import React from 'react'
import { Outlet, Link } from "react-router-dom";
import BottomBar from '../Components/BottomBar';
import Navbar from '../Components/Navbar';
import Signin from '../Pages/Signin/Signin';
const SubLayout = () => {
    return (
        <div className='relative flex flex-col justify-start gap-0 bg-primary'>
            <Navbar />
            <Outlet />
            <div className="py-[2rem] bg-gray-100"></div>
            <BottomBar />
        </div>
    )
}

export default SubLayout