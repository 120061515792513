import React, { useEffect, useState } from 'react'
import SingInImg from '../../../assets/images/icons/output-onlinepngtools (1).png';
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Formik, Field, Form } from 'formik';
import axios from "axios";
// import EncryptRsa from 'encrypt-rsa';
import { JSEncrypt } from "jsencrypt";
// import sha256 from 'crypto-js/sha256';
import { HmacSHA256, enc } from 'crypto-js';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

const EmailOTP = () => {
    const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

    // Initialize Firebase
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [applicationVerifier, setApplicationVerifier] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [ResendLoading, setResendLoading] = useState(false)
    const [CustomerEmail, setCustomerEmail] = useState<any>()
    const [CustomerStatus, setCustomerStatus] = useState<any>(null)
    const [confirmationResult, setConfirmationResult] = useState<any>()
    const [OTPStatus, setOTPStatus] = useState<any>(null);
    const [OTP, setOTP] = useState<any>('');
    const [UserExistsError, setUserExistsError] = useState<any>(null);
    const [CodeError, setCodeError] = useState<any>(null);
    const [EmailError, setEmailError] = useState<any>(null);
    const [TermsAndConditions, setTermsAndConditions] = useState<any>(true);
    const [TermsAndConditionsError, setTermsAndConditionsError] = useState<any>(false);
    const [BensaadServices, setBensaadServices] = useState<any>(true);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const navigate = useNavigate();

    function validateEmail(value: any) {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }
    const validate = (values: any) => {

        let errors = {
            email: "",
            OTP: "",
            password: '',
            confirmPassword: '',
        }
        if (!values.email) {
            errors.email = 'email is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }

        if (!values.OTP) {
            errors.OTP = 'OTP code is required'
        }

        if (!/(?=.{8,})/i.test(values.password)) {
            errors.password = 'Password field must be at least 8 Characters'
        } else if (!values.password) {
            errors.password = 'Password is required'
        }

        if (!/(?=.{8,})/i.test(values.confirmPassword)) {
            errors.confirmPassword = 'Confirm password field must be at least 8 Characters'
        } else if (values.confirmPassword != values.password) {
            errors.confirmPassword = 'Confirm Password must be same as Password'
        } else if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm password is required'
        }

        return errors
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            OTP: '',
            password: '',
            confirmPassword: '',
        },
        validate,
        onSubmit: (values) => {
            SubmitForm()
        },
    })

    const SubmitForm = async () => {
        setLoading(true)
    }

    const SendCode = async () => {
        // await sleep(100);
        let Email = formik.values.email;
        console.log(Email)
        await axios.post(`${APIURL}/api/website/customer/send/email`, {
            'AppKey': APIKEY,
            'email': Email,
        }).then((response) => {
            console.log(response)

            if (response.data.response.error === false) {
                console.log(response.data)
                setOTPStatus('CodeSent');
            } else if (response.data.response.error === true) {
                console.log(response.data.response)
                setUserExistsError('This E-mail has already been used');
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setLoading(false);
            setResendLoading(false);
        })
    }

    const CheckOTP = async () => {
        // await sleep(100);
        let OTPCode = await formik.values.OTP;
        let Email = await formik.values.email;
        let Password = await formik.values.password;


        await axios.post(`${APIURL}/api/email/code/check`, {
            'AppKey': APIKEY,
            'email': Email,
            'AuthCode': OTPCode,
        }).then(async (response) => {
            console.log(response.data)
            if (response.data.message.error === false) {
                setLoading(false);
                await setOTPStatus('CodeVerified');
                let EncryptedEmail = '';
                let EncryptedPassword = '';

                await axios.post(`${APIURL}/api/website/enc`, {
                    'Data': Email
                }).then((EmailResponse) => {
                    EncryptedEmail = EmailResponse.data.message.data
                })
                await axios.post(`${APIURL}/api/website/enc`, {
                    'Data': Password
                }).then((PasResponse) => {
                    EncryptedPassword = PasResponse.data.message.data
                })
                await setTimeout(async () => {
                    window.location.href = "/phone?uem=" + EncryptedEmail + '&pas=' + EncryptedPassword;
                }, 3000)
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        })

    }

    return (
        <div className='relative flex flex-col items-center justify-around gap-4 bg-primary'>
            <div className="w-[8rem] h-[6rem] mt-4 flex justify-center items-center">
                <img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
            </div>
            <button onClick={() => navigate(-1)} title="back" className=" absolute text-white w-8 h-8 top-[10px] left-[8px]">
                <i className="fa-regular fa-chevron-left"></i>
            </button>
            <div className="recaptcha-container" id="recaptcha-container"></div>
            <div className='flex flex-col items-center justify-around w-full gap-4 bg-white rounded-tr-3xl rounded-tl-3xl'>
                {OTPStatus === null ? (
                    <div className="text-[1.3rem] w-full flex justify-center font-[500] mb-1 mt-5">Enter Your E-mail</div>
                ) : OTPStatus === 'CodeSent' ? (
                    <div className="flex flex-col items-center justify-center mt-5 text-center">
                        <div className="text-[1.4rem] w-full font-[500] mb-1 ">Enter your code</div>
                        <div className="text-sm w-full font-[400] mb-1 ">We've sent a code to</div>
                        <div className="flex items-center justify-center w-full gap-2 mb-1 text-sm">
                            <div className="font-[700]">E-mail</div>
                            <div className="text-sm w-[34%] mt-[3px] mb-1 gap-2 flex justify-center items-center">
                                <div className="relative top-[3px] left-[6px] font-[700]">********</div>
                                <div className="font-[700]">{formik.values.email.slice(-2)}</div>
                            </div>
                        </div>
                    </div>
                ) : ''}

                {UserExistsError != null ? (
                    <div className="p-3 bg-red-300/80 text-base flex justify-start items-center gap-2 rounded-xl w-[82%] text-center">
                        <div className="text-base text-red-900 font-[600]">{UserExistsError}</div>
                    </div>
                ) : ''}

                {CodeError != null ? (
                    <div className="p-3 bg-red-200 text-base flex justify-start items-center gap-2 rounded-xl w-[82%] text-center">
                        <i className="fa-duotone fa-circle-check text-red-700 mt-[3px] text-lg"></i>
                        CodeError
                    </div>
                ) : ''}

                {EmailError != null ? (
                    <div className="p-3 bg-red-200 text-base flex justify-start items-center gap-2 rounded-xl w-[82%] text-center">
                        <i className="fa-duotone fa-circle-check text-red-700 mt-[3px] text-lg"></i>
                        emailError
                    </div>
                ) : ''}
                <Formik
                    initialValues={{
                        email: '',
                        OTP: '',
                        password: '',
                        confirmPassword: '',
                    }}
                    onSubmit={values => {
                        SubmitForm()
                    }}
                >
                    {({ errors, touched, validateField, validateForm }) => (
                        <Form onSubmit={formik.handleSubmit} method="post" className='w-[80%] mt-2 flex flex-col gap-4 justify-around items-start'>
                            {OTPStatus === null ? (
                                <>
                                    <div className="flex flex-col w-full gap-2">
                                        <div className="relative w-full">
                                            <input type="email" id="email" title='email' name="email"
                                                onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}
                                                className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.email && formik.values.email === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                            <label htmlFor="Email" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                                E-mail
                                            </label>
                                        </div>
                                        {formik.touched.email && formik.errors.email && (
                                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.email}</span>
                                        )}
                                    </div>
                                    <div className="flex flex-col w-full gap-2">
                                        <div className="relative w-full">
                                            <input
                                                type={`${showPassword === true ? 'text' : 'password'}`} title="password" name="password" id="password"

                                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.password && formik.values.password === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                            <label htmlFor="password" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                                Password
                                            </label>
                                            <i onClick={() => {
                                                setShowPassword(!showPassword)
                                            }} className={`fa-light ${showPassword === true ? 'fa-eye' : 'fa-eye-low-vision'} mt-1 text-zinc-400 w-[5%] flex-none absolute top-[17px] right-[20px] `}></i>
                                        </div>
                                        {formik.touched.password && formik.errors.password && (
                                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.password}</span>
                                        )}
                                    </div>

                                    <div className="flex flex-col w-full gap-2">
                                        <div className="relative w-full">
                                            <input
                                                type={`${showConfirmPassword === true ? 'text' : 'password'}`} title="confirmPassword" name="confirmPassword" id="confirmPassword"

                                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                value={formik.values.confirmPassword}
                                                className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.confirmPassword && formik.values.confirmPassword === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                            <label htmlFor="password" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                                Confirm password
                                            </label>
                                            <i onClick={() => {
                                                setShowConfirmPassword(!showConfirmPassword)
                                            }} className={`fa-light ${showConfirmPassword === true ? 'fa-eye' : 'fa-eye-low-vision'} mt-1 text-zinc-400 w-[5%] flex-none absolute top-[17px] right-[20px] `}></i>
                                        </div>
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.confirmPassword}</span>
                                        )}
                                    </div>

                                </>
                            ) : OTPStatus === 'CodeSent' ? (
                                <div className="flex flex-col w-full gap-2">
                                    <div className="relative w-full">
                                        <input type="number" id="OTP" title='OTP' name="OTP"
                                            onChange={formik.handleChange} onBlur={formik.handleBlur}
                                            value={formik.values.OTP}
                                            className={`block rounded-lg  font-[600] px-2.5 pb-2.5  ${formik.touched.OTP && formik.values.OTP === '' ? (' border border-red-500') : ''}  pt-5 w-full text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                                        <label htmlFor="OTP" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                            OTP Code
                                        </label>
                                    </div>
                                    {formik.touched.OTP && formik.errors.OTP && (
                                        <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.OTP}</span>
                                    )}
                                </div>
                            ) : OTPStatus === 'CodeVerified' ? (
                                <div className="flex flex-col items-center justify-center w-full">
                                    <div className="w-[200px] h-[200px] flex justify-center items-center ">
                                        <i className="fa-duotone fa-circle-check text-[5.5rem] text-[#0bb60b]"></i>
                                    </div>
                                    <div className="text-lg w-full text-center font-[500] mb-1 ">Your e-mail has been verified</div>
                                </div>
                            ) : ''}


                            <div className="flex items-center w-full my-2">
                                {OTPStatus === null ? (
                                    <button onClick={() => {
                                        validateForm().then(() => {
                                            setLoading(true);
                                            SendCode()
                                        })

                                        if (TermsAndConditions === false) {
                                            setTermsAndConditionsError(true)
                                        }
                                    }} type="button" title="Signin" disabled={loading || TermsAndConditions === true ? false : true} className={`${TermsAndConditions === true ? 'bg-primary' : 'bg-primaryLight'} text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'}  rounded-xl px-3 py-2 w-full `}>
                                        {loading === true ? '' : 'Next'}
                                        {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                                    </button>
                                ) : OTPStatus === 'CodeSent' ? (
                                    <button onClick={() => {
                                        setLoading(true);
                                        CheckOTP()
                                    }} type="button" title="Signin" disabled={loading} className={`bg-primary text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'} rounded-xl px-3 py-2 w-full`}>
                                        {loading === true ? '' : 'Verify E-mail'}
                                        {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                                    </button>
                                ) : OTPStatus === 'CodeVerified' ? '' : ''}
                            </div>

                            <div className="flex justify-center w-full ">
                                {OTPStatus === 'CodeSent' ? (
                                    <button onClick={() => {
                                        setResendLoading(true);
                                        SendCode()
                                    }} type="button" title="Resend code" disabled={ResendLoading} className={` text-gray-500 font-[400] text-base p-3`}>
                                        {ResendLoading === true ? '' : 'Resend code'}
                                        {ResendLoading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                                    </button>
                                ) : ''}

                            </div>

                        </Form>
                    )}
                </Formik>
                {OTPStatus === null ? (
                    <div className="flex flex-col gap-5 bg-gray-100/70 p-[18px] bottom-[21px] relative">
                        <label htmlFor="terms" className='relative flex items-start'>
                            {TermsAndConditionsError === true ? (
                                <div className="absolute top-[-41px] -left-2 bg-gray-900/80 text-white rounded-lg px-4 py-2  animate-bounce">
                                    You must accept our T&C,s
                                    <i className="fa-sharp fa-solid fa-caret-down relative top-[24px] left-[-197px] text-[#414652] text-[25px]"></i>
                                </div>
                            ) : ''}
                            <input type="checkbox" checked={TermsAndConditions} onChange={(e) => { setTermsAndConditions(!TermsAndConditions) }} name="TermsAndConditions" id="TermsAndConditions" className='text-primary mt-[6px]' />
                            <span className="ml-3">
                                I have read and agree to the <span className='text-blue-600'><a href="https://bensaad.co.uk/Terms-and-Conditions">Terms and Conditions</a></span> and  <span className='text-blue-600'><a href="https://bensaad.co.uk/Privacy-Policy">Privacy policy</a></span>
                            </span>
                        </label>
                        <label htmlFor="terms" className='flex items-start'>
                            <input type="checkbox" checked={BensaadServices} onChange={(e) => { setBensaadServices(!BensaadServices) }} name="" id="BensaadServices" className='text-primary mt-[6px]' />
                            <span className="ml-3 text-sm">
                                I am happy for Ben Saad Services to contact me occasionally about useful information and services that may be of interest to me
                            </span>
                        </label>
                    </div>
                ) : ''}

            </div>
        </div>
    )
}

export default EmailOTP
