import axios from 'axios';
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import SingInImg from '../../assets/images/icons/output-onlinepngtools (1).png';

const CompletePage = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [searchParams, setSearchParams] = useSearchParams('');
    const DecryptedData = searchParams.get("cutd") || '';
    const Email = localStorage.getItem('Email');

    const EncData = JSON.parse(DecryptedData);
    console.log(EncData)
    // console.log(EncData.ProofDocFileName)

    useEffect(() => {
        CreateCustomer()
        UploadID()
    }, [])



    const CreateCustomer = async () => {
        console.log(EncData)
        await axios.post(`${APIURL}/api/website/customer/create/last/step`, {
            'AppKey': APIKEY,
            'email': Email,
            'data': EncData
        }).then((Response) => {
            console.log(Response)
            localStorage.setItem('RegistrationStatus', 'completed')
        }).catch((error) => {
            console.log(error)
        })
    }

    const UploadID = async () => {
        console.log('uploading for ...', localStorage.getItem('Email'))


        await axios.post(`${APIURL}/api/upload/documents`, {
            'AppKey': APIKEY,
            'doc_type': EncData.DocType,
            'proof_address_type': EncData.ProofAddressDocType,
            'id_front_file': EncData.IDDocFileName != null ? EncData.DocFileName : '',
            'id_back_file': EncData.IDDocFile2Name != null ? EncData.DocFile2Name : '',
            'proof_front_file': EncData.ProofDocFileName,
            'proof_back_file': EncData.ProofDocFileName,
            'email': localStorage.getItem('Email'),
        }, {
            headers: { "Content-Type": "multipart/form-data" },
        }).then((Response) => {
            console.log(Response)
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div className='flex flex-col items-center justify-around gap-4 bg-primary'>
            <div className="w-[10rem] h-[6rem] mt-4 flex justify-center items-center">
                <img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
            </div>
            <div className='flex flex-col items-center justify-around w-full gap-4 bg-white rounded-tr-3xl rounded-tl-3xl'>
                <div className='w-[80%] mt-2 flex flex-col gap-4 justify-around items-start'>

                    <div className="flex flex-col justify-center items-center w-full">
                        <div className="w-[200px] h-[200px] flex justify-center items-center ">
                            <i className="fa-duotone fa-circle-check text-[6rem] ml-[10px] text-[#0bb60b]"></i>
                        </div>
                        <div className="text-lg w-full text-center font-[500] mb-1 ">Your account profile has been completed successfully</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletePage