import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


export default function Occupations({ label, formData, setFormData, Data }: any) {

    /* 
    <option value="Company_director">Company director</option>
    <option value="Pilot">Pilot</option>
    <option value="Flight_attendant">Flight attendant</option>
    <option value="Plumber">Plumber</option>
    <option value="Carer">Carer</option>
    <option value="Labourer">Labourer</option>
    <option value="Baker">Baker</option>
    <option value="Mechanic">Mechanic</option>
    <option value="Chef">Chef</option>
    <option value="Journalist">Journalist</option>
    <option value="Female">Female</option>
    <option value="Teacher">Teacher </option>
    <option value="Waiter">Waiter</option>
    <option value="Doctor_Driver">Doctor</option>
    <option value="Taxi_Driver">Taxi Driver</option>
    <option value="Social_worker">Social worker</option>
    <option value="House_wife">House wife</option>
    <option value="Unempolyed">Unempolyed</option>
    <option value="Warehouse_operator">Warehouse operator</option>
    <option value="Engineer">Engineer</option>
    <option value="Manager">Manager</option>
    <option value="Secretary">Secretary</option>
    <option value="Police_officer">Police officer</option>
    
    
    */
    const OccupationsData = [
        { id: 0, name: 'Select occupation' },
        { id: 1, name: 'Company director' },
        { id: 2, name: 'Pilot' },
        { id: 3, name: 'Flight_attendant' },
        { id: 4, name: 'Plumber' },
        { id: 5, name: 'Carer' },
        { id: 6, name: 'Labourer' },
        { id: 7, name: 'Baker' },
        { id: 8, name: 'Mechanic' },
        { id: 9, name: 'Chef' },
        { id: 10, name: 'Journalist' },
        { id: 11, name: 'Female' },
        { id: 12, name: 'Teacher' },
        { id: 13, name: 'Waiter' },
        { id: 14, name: 'Doctor_Driver' },
        { id: 15, name: 'Taxi_Driver' },
        { id: 16, name: 'Social_worker' },
        { id: 17, name: 'House_wife' },
        { id: 18, name: 'Unempolyed' },
        { id: 19, name: 'Warehouse_operator' },
        { id: 20, name: 'Engineer' },
        { id: 21, name: 'Manager' },
        { id: 22, name: 'Secretary' },
        { id: 23, name: 'Police_officer' },
    ]
    const [selected, setSelected] = useState(OccupationsData[0])
    const [query, setQuery] = useState('')

    const filteredPeople =
        query === ''
            ? OccupationsData
            : OccupationsData.filter((country: any) =>
                country.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )


    return (
        <div className=" top-16">
            <Combobox as="div" value={formData.occupation === '' ? OccupationsData[0] : formData.occupation} onChange={(e) => {
                setSelected(e.name)
                setFormData({
                    ...formData,
                    occupation: e.name.replace("_", " "),
                })
            }}>
                <div className="relative mt-1">
                    <div className="relative z-[999] InputBtn w-full h-14 group peer cursor-default rounded-lg bg-white py-3 pl-3 pr-10 text-left border border-gray-300 hover:border hover:border-primary focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">{label}</span>
                        <Combobox.Input
                            className="w-[90%] border-none p-0 text-sm leading-5 font-[500] text-gray-900 focus:ring-0 absolute bottom-[13px] left-[11px]"
                            displayValue={formData.occupation}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            {OccupationsData.length === 0 ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            )}
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute z-[9999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredPeople.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredPeople!.map((person: any) => (
                                    <Combobox.Option
                                        key={person.id}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {person.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary'
                                                            }`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
