import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';

import Beneficiaries from '../Components/Beneficiaries';
import BanksList from '../Components/BanksList';
import axios from 'axios';
import AccountNumberType from '../Components/AccountNumberType';
const APIKEY = process.env.REACT_APP_API_KEY;
const APIURL = process.env.REACT_APP_API_URL;


const BankDetails = ({ formData, setFormData }: any) => {
    const [loading, setLoading] = useState(false)
    const [Data, setData] = useState<any>([])
    const getData = async () => {
        await axios.post(`${APIURL}/api/website/bankslist`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then((Response) => {
            setData(Response.data.response.data);
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        getData()
    }, [formData.DeliveryMode])

    const [enabled, setEnabled] = useState(false)

    const validate = (values: any) => {
        let errors = {
            AccountName: "",
            AccountNumber: "",
        }

        if (!values.AccountName) {
            errors.AccountName = 'Account name is required'
        }

        if (!values.AccountNumber) {
            errors.AccountNumber = 'Account number is required'
        }

        return errors
    }
    const formik = useFormik({
        initialValues: {
            AccountName: '',
            AccountNumber: '',
        },
        validate,
        onSubmit: (values) => {
        },
    })

    return (
        <div>
            <div className='flex flex-col items-start justify-around w-full gap-4 '>
                <div className="text-xl relative w-full text-center font-[700] mt-2 mb-4 text-primary">Bank Details</div>

                <div className="flex flex-col items-start justify-around w-full gap-4 p-4 bg-white rounded-xl">
                    <div className="w-full ">
                        <BanksList label='Select bank' formData={formData} setFormData={setFormData} Data={Data} />
                    </div>

                    <div className="flex flex-col w-full gap-2">
                        <div className="relative w-full">
                            <input type="text" id="AccountName" title='AccountName' name="AccountName"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setFormData({
                                        ...formData,
                                        AccountName: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                value={formData.AccountName}
                                className={`block rounded-lg  font-[600] px-2.5 pb-2.5  ${formik.touched.AccountName && formik.values.AccountName === '' ? (' border border-red-500') : ''}  pt-5 w-full text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="AccountName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                Account name
                            </label>
                        </div>
                        {formik.touched.AccountName && formik.errors.AccountName && (
                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.AccountName}</span>
                        )}
                    </div>

                    <div className="flex flex-col w-full gap-2">
                        <div className="relative w-full">
                            <input type="text" id="AccountNumber" title='AccountNumber' name="AccountNumber"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setFormData({
                                        ...formData,
                                        AccountNumber: e.target.value,
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                value={formData.AccountNumber}
                                className={`block rounded-lg  font-[600] px-2.5 pb-2.5  ${formik.touched.AccountNumber && formik.values.AccountNumber === '' ? (' border border-red-500') : ''}  pt-5 w-full text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="AccountNumber" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                Account number
                            </label>
                        </div>
                        {formik.touched.AccountNumber && formik.errors.AccountNumber && (
                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.AccountNumber}</span>
                        )}
                    </div>

                    <div className="w-full my-2">
                        <AccountNumberType label='Account number type' formData={formData} setFormData={setFormData} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BankDetails