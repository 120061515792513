import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Preview = ({ formData, setFormData }: any) => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true)
    const [UserData, setUserData] = useState<any>([])
    const [BenData, setBenData] = useState<any>([])
    const getData = async () => {
        await axios.post(`${APIURL}/api/website/user/data`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then(DataResponse => {
            const Response = DataResponse.data.response;
            setUserData(Response.data)
        })
    }

    const getBenData = async () => {
        await axios.post(`${APIURL}/api/website/beneficiaries/one`, {
            'AppKey': APIKEY,
            'BID': formData.BeneficiaryID,
            'token': localStorage.getItem('Token'),
        }).then(async (DataResponse) => {
            const Response = DataResponse.data.response;
            await setBenData(Response.data)
        })
    }


    const CreateTransaction = async () => {
        console.log(JSON.stringify(formData))

        localStorage.setItem('Transaction', JSON.stringify(formData));
        setLoading(false)

    }

    useEffect(() => {
        getData()
        getBenData()
        /* create transaction */
        // console.log('useEffect Called for ' + formData.BeneficiaryID)
        CreateTransaction()
    }, [])
    let date = new Date().toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })

    return (
        <div className='flex flex-col items-start justify-around w-full gap-1'>
            <div className="text-xl relative w-full text-center font-[700] mt-2 mb-4 text-primary">Transaction Review</div>

            {loading === true ? (
                <div className="flex items-center justify-center w-full">
                    <div className="flex items-center justify-center w-16 h-16 text-2xl shadow rounded-xl">
                        <i className="fa-solid fa-spinner-third animate-spin"></i>
                    </div>
                </div>
            ) : (
                <div className="w-full" >
                    <div className="flex flex-col gap-2">

                        <div className="flex flex-col gap-3 p-4 bg-white shadow rounded-xl">
                            <div className="flex flex-col">
                                <div className="text-sm text-gray-400">Date</div>
                                <div className="text-base text-gray-800">{date}</div>
                            </div>

                            <div className="flex justify-between">

                                <div className="flex flex-col">
                                    <div className="text-sm text-gray-400">Destination</div>
                                    <div className="text-base text-gray-800">{formData.beneficiaryCountry.name}</div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="text-sm text-gray-400">Receiver name</div>
                                    <div className="text-base text-gray-800">{formData.Beneficiary}</div>
                                    {/* <div className="text-xs text-gray-800">+{BenData.Phone}</div> */}
                                </div>
                            </div>

                            <div className="flex justify-between">
                                <div className="flex flex-col">
                                    <div className="text-sm text-gray-400">Currency</div>
                                    <div className="text-base text-gray-800">{formData.ReceiveCurrency}</div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="text-sm text-gray-400">Exchange rate</div>
                                    <div className="text-base text-gray-800">{formData.Rate}</div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-1 ">
                            <div className="flex flex-col gap-3 p-4 bg-white shadow rounded-xl">

                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-gray-400">Total to Receive</div>
                                    <div className="flex text-base text-gray-800">
                                        <div className="text-base">
                                            {formData.ReceiveAmount}
                                        </div>

                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-gray-400">Send amount</div>
                                    <div className="text-base text-gray-800">
                                        <i className="mr-1 text-sm text-gray-500 fa-regular fa-sterling-sign"></i>
                                        {formData.SendAmount}
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-gray-400">Charges</div>
                                    <div className="text-base text-gray-800">
                                        <i className="mr-1 text-sm text-gray-500 fa-regular fa-sterling-sign"></i>
                                        0.00
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-3 p-4 bg-white shadow rounded-xl">
                                <div className="flex items-center justify-between">
                                    <div className="text-xl text-gray-900 font-[600]">Total</div>
                                    <div className="text-xl font-[600] text-gray-800">
                                        <i className="relative mb-1 mr-1 text-base text-gray-500 -top-[2px] fa-regular fa-sterling-sign"></i>
                                        {formData.SendAmount}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <form className="mt-2" method="POST" action="https://payments.securetrading.net/process/payments/choice">
                        {/* <input type="hidden" name="sitereference" value="test_bensaadservicesltd102376" /> */}
                        <input type="hidden" name="sitereference" value="bensaadservicesltd102377" />
                        <input type="hidden" name="data" value={formData} />
                        <input type="hidden" name="merchantlogo" value="merchantlogo.png" />
                        <input type="hidden" name="stprofile" value="default" />
                        {/* {{-- < input type="hidden" name="stdefaultprofile" value="st_paymentcardonly"> --}} */}
                        <input type="hidden" name="currencyiso3a" value="GBP" />
                        <input type="hidden" name="mainamount" value={formData.SendAmount} />
                        <input type="hidden" name="version" value="2" />
                        <input type="hidden" name="billingstreet" className="st-empty" />
                        <input type="hidden" name="billingemail" value={localStorage.getItem('Email') || ''} className="st-empty" />
                        <input type="hidden" name="ruleidentifier" value="STR-6" />
                        {/* {{-- < input type="hidden" name="stprofile" value="default"> --}} */}
                        <input type="hidden" name="stdefaultprofile" value="st_iframe_cardonly" />
                        {/* {{-- < input type="hidden" name="stdefaultprofile" value="st_paymentcard"> --}} */}
                        <input type="hidden" name="successfulurlredirect" value="http://164.92.203.95/payment/successful" />
                        {/* <input type="hidden" name="successfulurlredirect" value="http://localhost:3000/payment/successful" /> */}
                        {/* {{-- < input type="hidden" name="successfulurlredirect" value="http://127.0.0.1:8001/payment/successful"> --}} */}
                        {/* {{-- < input type="hidden" name="successfulurlredirect" value="http://127.0.0.1:8001/payment/sdfsdfsdfsdfdsf/successful/"> --}} */}
                        <input type="hidden" name="stextraurlredirectfields" value={localStorage.getItem('ID') || ''} />
                        {/* {{-- 4111 1111 1111 1111 --}} */}


                        <div className={`flex flex-col mb-1 bg-white p-3 rounded-xl items-center justify-between w-full `}>
                            <input type="submit"
                                className={` bg-gradient-to-r mb-0 from-orange-600/50 via-orange-600/70 to-orange-600/90 w-full text-white rounded-lg p-3 `}
                                value="Confirm" />
                        </div>

                    </form>
                </div>
            )}
        </div>
    )
}

export default Preview


function getUid(id: any) {
    if (id > 0 && id <= 9) {
        return 'BSTN00' + id;
    } else if (id > 9 && id <= 99) {
        return 'BSTN0' + id;
    } else if (id > 99) {
        return 'BSTN' + id;
    }
}
