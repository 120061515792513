import React, { useState } from 'react'
import Loading from '../../../Components/Loading';
import PersonalInfo from './Steps/PersonalInfo';
import { useFormik } from 'formik';
import axios from 'axios';
import MoreInfo from './Steps/MoreInfo';

const Create = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false)
    const validate = (values: any) => {
        let errors = {
            beneficiary: "",
            CountryError: false,
        }

        if (!values.beneficiary) {
            errors.beneficiary = 'Beneficiary is required'
        }

        return errors
    }
    const formik = useFormik({
        initialValues: {
            beneficiary: '',
            beneficiaryCountry: '',
            CountryError: false,
            EnglishName: '',
            ArabicName: '',
            phone: '',
            AccountName: '',
            AccountNumber: '',
            IsCreated: false,
        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
            handleSubmit()
        },
    })

    const [formData, setFormData] = useState({
        beneficiary: '',
        beneficiaryCountry: '',
        CountryError: false,
        EnglishName: '',
        ArabicName: '',
        phone: '',
        AccountName: '',
        AccountNumber: '',
        Relationship: '',
        IsCreated: false,

    });

    function handleSubmit() {
        setLoading(true);
        if (page === 0) {
            setPage(page + 1);
            setLoading(false);
        } else if (page === 1) {
            CreateReceiver();
        }
    }

    const CreateReceiver = async () => {
        setLoading(true)
        await axios.post(`${APIURL}/api/website/beneficiaries/create`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
            'full_name': formData.EnglishName,
            'arabic_name': formData.ArabicName,
            'CustomerID': localStorage.getItem('ID'),
            'relationship': formData.Relationship,
            'phone': formData.phone,
            'country': formData.beneficiaryCountry,
        }).then(async (response) => {
            setLoading(false)
            setFormData({
                ...formData,
                IsCreated: true
            });
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    const CreateForm = () => {
        switch (page) {
            case 0:
                return <PersonalInfo formData={formData} setFormData={setFormData} />;
            case 1:
                return <MoreInfo formData={formData} setFormData={setFormData} />;
        }
    }
    return (
        <div className='flex flex-col gap-0 justify-start bg-white rounded-t-2xl'>
            <div className="p-3">
                {formData.IsCreated === false ? (
                    <form onSubmit={formik.handleSubmit} method="post" className='w-full mt-0 flex flex-col gap-2 justify-center items-start w-full'>
                        <div className="w-full">
                            {CreateForm()}
                        </div>
                        <div className={`flex flex-col items-center justify-between w-full ${page === 3 ? "mb-4 mt-1" : "my-2 mt-6 "}`}>
                            {page <= 1 ? (
                                <button onClick={() => {
                                    handleSubmit()
                                }} type="button" title="Signin" disabled={loading || formData.CountryError} className={`w-full text-white ${loading === true || formData.CountryError === true ? 'bg-gray-200 text-gray-800' : 'bg-gradient-to-r from-orange-600/50 via-orange-600/70 to-orange-600/90 '}  rounded-lg p-3 `}>

                                    {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (
                                        page === 1 ? 'Confirm' : 'Continue'
                                    )}
                                </button>
                            ) : ''}
                            {
                                page > 0 && <button onClick={() => {
                                    setPage(page - 1)

                                }} type="button" title="Signin" disabled={loading} className={`text-gray-700 rounded-xl p-3 w-[40%]`}>
                                    {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : 'Back'}
                                </button>
                            }
                            {
                                page == 0 && <div className=""></div>
                            }

                        </div>

                    </form>
                ) : (
                    <div className="flex flex-col justify-center items-center w-full">
                        <div className="w-[200px] h-[200px] flex justify-center items-center ">
                            <i className="fa-duotone fa-circle-check text-[5.5rem] text-[#0bb60b]"></i>
                        </div>
                        <div className="text-lg w-full text-center font-[500] mb-1 ">Receiver Created successfully</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Create
