import React, { Fragment, useEffect, useState } from 'react'
import { useFormik } from 'formik';
import axios from "axios";
import Loading from '../../Components/Loading';
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
const Calculator = () => {

    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false)
    const [SelectedCurrency, setSelectedCurrency] = useState<any>('SDG')
    const [Amount, setAmount] = useState<any>()
    const [Received, setReceived] = useState<any>()
    const [CountryData, setCountryData] = useState<any>()
    const [Countries, setCountries] = useState<any>([])
    const [SelectedCountry, setSelectedCountry] = useState<any>('')

    useEffect(() => {
        getData()
        getCountries()
    }, [])

    const getCountries = async () => {
        await axios.post(`${APIURL}/api/website/countries`).then((Response) => {
            // Response.data.message.data
            // console.log(Response.data.response.data)
            setCountries(Response.data.response.data)
            setLoading(false)
        })
    }

    const getData = async () => {
        // await axios.post(`${APIURL}/api/website/countries/sudan`, {
        //     'AppKey': APIKEY,
        // }).then((Response) => {
        //     // Response.data.message.data
        //     console.log(Response.data.response.data)
        //     setCountryData(Response.data.response.data)
        //     setLoading(false)
        // })
    }


    const CountriesMenu = () => {
        const [query, setQuery] = useState('')
        // console.log(Countries)

        const filteredPeople =
            query === ''
                ? Countries
                : Countries?.filter((country: any) =>
                    country?.name
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )

        return (
            <div className="w-full top-16">
                <Combobox as="div" value={SelectedCountry?.name} onChange={(e: any) => {
                    console.log('started...')
                    // formik.handleChange(e)
                    setLoading(true)
                    setSelectedCountry(e)
                    setCountryData(e)
                    setLoading(false)
                }}

                >
                    <div className="relative mt-1">
                        <div className="relative z-[999] InputBtn w-full h-14 group peer cursor-default rounded-lg bg-white py-3 pl-3 pr-10 text-left border border-gray-300 hover:border hover:border-primary focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                            <span className="absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                                Countries
                            </span>
                            <Combobox.Input
                                className="w-[90%] border-none p-0 text-sm leading-5 font-[500] text-gray-900 focus:ring-0 absolute bottom-[13px] left-[11px]"
                                displayValue={SelectedCountry}
                                onChange={(event) => {
                                    setQuery(event.target.value)
                                }}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                {Countries.length === 0 ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (
                                    <ChevronUpDownIcon
                                        className="w-5 h-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                )}
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute z-[9999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredPeople.length === 0 && query !== '' ? (
                                    <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                        Nothing found.
                                    </div>
                                ) : (
                                    filteredPeople!.map((person: any) => (
                                        <Combobox.Option
                                            key={person.id}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-gray-900'
                                                }`
                                            }
                                            value={person}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                            }`}
                                                    >
                                                        {person.name}
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary'
                                                                }`}
                                                        >
                                                            <i className="fa-duotone fa-circle-check"></i>
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>

            </div>
        )
    }

    return (
        <div className='relative bg-gray-100 rounded-t-2xl'>
            <div className="flex flex-col justify-start gap-2 p-3">
                <div className='flex flex-col items-start justify-around w-full gap-4 p-4 bg-white rounded-2xl'>
                    <div className="text-[1.4rem] font-[500] mb-1 text-primary w-full flex justify-center">Today's Exchange rate</div>

                    <CountriesMenu />
                    {loading === true ? (
                        <Loading />
                    ) : SelectedCountry != '' ? (
                        <div className="flex flex-col gap-5">
                            <h3 className="mb-0 text-sm font-medium text-gray-900 dark:text-white">Selected currency</h3>
                            <ul className="grid w-full grid-cols-2 gap-4 mb-2">
                                <li className='w-[9rem]'>
                                    <input
                                        // checked
                                        onChange={(e) => {
                                            console.log(CountryData?.currency_name)
                                            setSelectedCurrency(CountryData?.currency_name)
                                        }}
                                        value={CountryData?.currency_name}
                                        type="radio" id="Cur1" name="SelectedCurrency" className="hidden peer" required />
                                    <label htmlFor="Cur1" className={`relative inline-flex h-[3rem] shadow-md items-center justify-between w-full p-3 text-gray-500 bg-white border ${SelectedCurrency === CountryData?.currency_name ? 'border-primary' : 'border-gray-200'} rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-white dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">{CountryData?.currency_name}</div>
                                            {/* <div className="w-full text-xs">1.00 GBP = {CountryData?.online_rate}</div> */}
                                        </div>
                                        {SelectedCurrency === CountryData?.currency_name ? (
                                            <div className=" absolute top-[3px] right-[3px]">
                                                <i className="w-6 h-6 ml-3 text-green-400 fa-solid fa-check-circle"></i>
                                            </div>
                                        ) : ''}
                                    </label>
                                </li>

                                {CountryData?.dollar_status === 1 ? (
                                    <li className='w-[9rem]'>
                                        <input
                                            onChange={(e) => {
                                                console.log('USD')
                                                setSelectedCurrency("USD")
                                            }}
                                            value="USD"
                                            type="radio" id="Cur2" name="SelectedCurrency" className="hidden peer" required />
                                        <label htmlFor="Cur2" className={`relative inline-flex h-[3rem] shadow-md items-center justify-between w-full p-3 text-gray-500 bg-white border ${SelectedCurrency === 'USD' ? 'border-primary' : 'border-gray-200'} rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-white dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}>
                                            <div className="block">
                                                <div className="w-full text-lg font-semibold">USD</div>
                                                {/* <div className="w-full text-xs">1.00 GBP = {CountryData?.dollar_rate}</div> */}
                                            </div>
                                            {SelectedCurrency === 'USD' ? (
                                                <div className=" absolute top-[3px] right-[3px]">
                                                    <i className="w-6 h-6 ml-3 text-green-400 fa-solid fa-check-circle"></i>
                                                </div>
                                            ) : ''}
                                        </label>
                                    </li>
                                ) : ''}

                            </ul>
                        </div>
                    ) : ''}

                    <form method="post" className='flex flex-col items-start justify-center w-full gap-4 mt-2'>
                        <div className="flex justify-center w-full gap-3 text-gray-400">
                            <div className="">1.00 GBP</div>
                            <div className=""> =</div>
                            {SelectedCurrency === 'USD' ? (
                                <div className="">
                                    <i className="mr-px text-sm fa-regular fa-dollar-sign"></i>
                                    {CountryData?.dollar_rate}</div>
                            ) : (
                                <div className="">{CountryData?.online_rate} SDG</div>
                            )}
                        </div>
                        <div className="grid w-full grid-cols-2 gap-2">
                            <div className="relative w-full">
                                <div className="relative">
                                    <input type="number"
                                        onChange={(e) => {
                                            let SentAmount = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'GBP',
                                            }).format(Number(e.target.value));
                                            setAmount(SentAmount)
                                            if (SelectedCurrency === 'USD') {
                                                let Received = Number(e.target.value) * CountryData?.dollar_rate
                                                setReceived(Received)
                                            } else {
                                                let Received = Number(e.target.value) * CountryData?.online_rate
                                                setReceived(Received.toFixed(2))
                                            }
                                        }}
                                        id="amount" className="block font-[600] px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer" placeholder=" " />
                                    <label htmlFor="amount" className="absolute text-base text-gray-300 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                        Amount
                                    </label>
                                </div>
                            </div>
                            <div className="relative w-full">

                                <div className="relative">
                                    <input type="number" value={Received} id="Received" className="block font-[600] px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer" placeholder=" " />
                                    <label htmlFor="Received" className="absolute text-base text-gray-300 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                        Receiver gets
                                    </label>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="relative flex flex-col items-end justify-start w-full p-4 bg-blue-100 shadow rounded-xl">
                    <div className="flex justify-end gap-3 font-[600] text-lg">
                        <div className="">Total : </div>

                        <div className="">{Amount}</div>
                    </div>
                </div>


            </div>
        </div >
    )
}

export default Calculator



