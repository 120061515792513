import React, { useEffect, useState } from 'react'
import SingInImg from '../../assets/images/icons/output-onlinepngtools (1).png';
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import axios from "axios";
import { setTimeout } from 'timers/promises';
import { useNavigate, useSearchParams } from "react-router-dom";
import AccountInfo from './Steps/AccountInfo';
import PersonalInfo from './Steps/PersonalInfo';
import PostCode from './Steps/PostCode';
import Documents from './Steps/Documents';
import SumSub from './Steps/SumSub';

const Signup = () => {
	const APIKEY = process.env.REACT_APP_API_KEY;
	const APIURL = process.env.REACT_APP_API_URL;
	const [showPassword, setShowPassword] = useState(false)
	const [loading, setLoading] = useState(false)
	const [CustomerData, setCustomerData] = useState([])
	const [CustomerToken, setCustomerToken] = useState('')
	const [SubmitBtn, setSubmitBtn] = useState(true)
	const navigate = useNavigate();
	const [page, setPage] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams('');
	const DecryptedEM = searchParams.get("uem");
	const DecryptedPH = searchParams.get("ph");
	const [SumSubData, setSumSubData] = useState<any>([])
	const [Occupations, setOccupations] = useState<any>([])
	const [Countries, setCountries] = useState<any>([]);
	const [SumSubAccessToken, setSumSubAccessToken] = useState<any>('')
	const [SumSubApplicantId, setSumSubApplicantId] = useState<any>('')
	const [SumSubUserId, setSumSubUserId] = useState<any>('')
	const DecryptD = async (Decrypted: any) => {
		let DecryptedData = '';
		axios.post(`${APIURL}/api/website/dec`).then((EmailResponse) => {
			DecryptedData = EmailResponse.data.message.data
		})
	}

	useEffect(() => {
		// getSumSubData()
	}, [SumSubAccessToken])

	const GetCountries = async () => {
		axios.post(`${APIURL}/api/sender/countries`, {
			'AppKey': APIKEY,
		}).then(async (response) => {
			setCountries(response.data.message.data)
		}).catch(function (error) {
			console.log(error);
		})
	}

	const getSumSubData = async () => {
		let AccessToken = '';
		await axios.post(`${APIURL}/api/sumsub`).then((Response) => {
			setSumSubAccessToken(Response.data.response.accessToken)
			setSumSubApplicantId(Response.data.response.applicantId)
			setSumSubUserId(Response.data.response.userId)
			console.log(Response.data.response.accessToken)

			if (localStorage.getItem('SumSubAccessToken') != null) {
				localStorage.removeItem('SumSubAccessToken')
			}
			if (localStorage.getItem('SumSubApplicantId') != null) {
				localStorage.removeItem('SumSubApplicantId')
			}
			if (localStorage.getItem('SumSubUserId') != null) {
				localStorage.removeItem('SumSubUserId')
			}

			localStorage.setItem('SumSubAccessToken', Response.data.response.accessToken);
			localStorage.setItem('SumSubApplicantId', Response.data.response.applicantId);
			localStorage.setItem('SumSubUserId', Response.data.response.userId);

		})
	}



	const validate = (values: any) => {
		let errors = {
			fullName: "",
			password: ""
		}

		if (!values.fullName) {
			errors.fullName = 'E-mail is required'
		}

		if (!values.password) {
			errors.password = 'Password is required'
		} else if (!/(?=.{8,})/i.test(values.password)) {
			errors.password = "Must Contain 8 Characters"
		}

		return errors
	}

	const formik = useFormik({
		initialValues: {
			fullName: '',
			arabicName: '',
			password: '',
			confirmPassword: '',
			gender: '',
			PostCode: '',
			Email: '',
			Phone: '',
			DateOfBirth: '',
			occupation: '',
			occupations: '',
			nationality: '',
			Countries: '',
			DocType: '',
			DocFile: '',
			DocFile2: '',
			ProofAddressDocType: '',
			ReviewStatus: '',
			ProofDocFile: '',
			DocFileName: '',
			DocFile2Name: '',
			DocFileNameLoading: true,
			ProofDocFileName: '',
			ProofDocFileNameLoading: true,
			SumSubData: '',
		},
		validate,
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2))
			handleSubmit()
		},
	})

	const [formData, setFormData] = useState({
		fullName: '',
		Email: '',
		Phone: '',
		arabicName: '',
		password: '',
		confirmPassword: '',
		gender: '',
		PostCode: '',
		DateOfBirth: '',
		occupation: '',
		occupations: '',
		nationality: '',
		Countries: '',
		DocType: '',
		DocFile: '',
		ProofAddressDocType: '',
		ReviewStatus: '',
		ProofDocFile: '',
		IDDocFileName: "",
		IDDocFile2Name: "",
		DocFileNameLoading: true,
		ProofDocFileName: '',
		ProofDocFileNameLoading: true,
		SumSubData: '',
	});

	function handleSubmit() {
		setLoading(true);
		if (page === 0) {
			// if (formData.PostCode != '') {
			setPage(page + 1);
			// }
			setLoading(false);
		} else if (page === 1) {
			if (formData.fullName != '' && formData.arabicName != '' && formData.DateOfBirth != '' && formData.gender != "" && formData.occupation != '' && formData.nationality != "") {

				setPage(page + 1);
			}
			setLoading(false);
		} else if (page === 2) {
			// if (formData.DocFile != '' && formData.DocType != '') {
			getSumSubData()
			global.setTimeout(() => {
				setPage(page + 1);
				setLoading(false);
			}, 3000)
			// }
		} else if (page === 3) {
			if (formData.ReviewStatus === "completed") {
				setSubmitBtn(false)
				setFormData({
					...formData,
					Email: localStorage.getItem('Email') || '',
				});
			}
		}


	}
	const CompleteSignUp = async () => {
		setLoading(true);
		await global.setTimeout(() => {
			window.location.href = "/sign-up/complete?cutd=" + JSON.stringify(formData);
			setLoading(false);
		}, 3000)
	}

	const SignUpForm = () => {
		switch (page) {
			case 0:
				return <PostCode formData={formData} onChange={formik.handleChange} onBlur={formik.handleBlur} setFormData={setFormData} />;
			case 1:
				return <PersonalInfo formData={formData} setFormData={setFormData} />;
			case 2:
				return <Documents formData={formData} setFormData={setFormData} />;
			case 3:
				return <SumSub formData={formData} setFormData={setFormData} />;
			default:
				return <PostCode formData={formData} setFormData={setFormData} />;
		}
	}

	return (
		<div className='flex flex-col items-center justify-around gap-4 bg-primary'>
			<div className="w-[10rem] h-[6rem] mt-4 flex justify-center items-center">
				<img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
			</div>
			<div className='flex flex-col items-center justify-around w-full gap-4 bg-white rounded-tr-3xl rounded-tl-3xl'>
				<form onSubmit={formik.handleSubmit} method="post" className='w-[80%] mt-2 flex flex-col gap-4 justify-around items-start'>
					{SignUpForm()}

					<div className="flex items-center justify-between w-full my-2">
						{
							page > 0 && <button onClick={() => setPage(page - 1)} type="button" title="Signin" disabled={false} className={`bg-gray-300 text-gray-800 ${loading === true ? 'bg-gray-200' : ''}  rounded-xl p-3 w-[40%]`}>
								Back
								{/* {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''} */}
							</button>
						}
						{
							page == 0 && <div className=""></div>
						}

						{page < 3 ? (
							<button onClick={() => {
								handleSubmit()
							}} type="button" title="Signin" disabled={loading} className={`bg-primary text-white ${loading === true ? 'bg-gray-200' : ''}  rounded-xl p-3 w-[40%]`}>
								{loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : 'Next'}
							</button>
						) : ''}


						{page === 3 ? (
							<button onClick={(e) => {
								CompleteSignUp()
							}} type="submit" title="Submit" disabled={loading || SubmitBtn} className={`bg-primary text-white ${loading === true ? 'bg-gray-200' : ''}  rounded-xl p-3 w-[40%]`}>
								{loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : 'Submit'}
							</button>
						) : ''}

					</div>

				</form>
			</div >
		</div >
	)
}

export default Signup