import { useState, useEffect } from 'react'
import SingInImg from '../../assets/images/icons/output-onlinepngtools (1).png';
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from 'formik';
import axios from "axios";

const Signin = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [showPassword, setShowPassword] = useState(false)
    const [CredentialsError, setCredentialsError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [CustomerData, setCustomerData] = useState([])
    const [CustomerToken, setCustomerToken] = useState('')
    const [CustomerEmail, setCustomerEmail] = useState<any>('')
    const [searchParams, setSearchParams] = useSearchParams('');
    const DecryptedP = searchParams.get("uem");

    const navigate = useNavigate();

    useEffect(() => {
        // if (localStorage.getItem('Token')) {
        //     navigate('/home')
        // }
        getEmail();
    }, [])

    const getEmail = async () => {
        let Email = '';
        if (DecryptedP != null) {
            axios.post(`${APIURL}/api/website/dec`, {
                'Data': DecryptedP
            }).then((Response) => {
                Email = Response.data.message.data
                setCustomerEmail(Email);
            })
        } else {
            setCustomerEmail(null);
        }
    }

    const validateEmail = (email: any) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validate = (values: any) => {
        const EmailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let errors = {
            email: "",
            password: ""
        }

        if (!values.email) {
            errors.email = 'E-mail is required'
        } else if (!validateEmail(values.email)) {
            errors.email = 'Invalid email address'
        }

        if (!values.password) {
            errors.password = 'Password is required'
        } else if (!/(?=.{8,})/i.test(values.password)) {
            errors.password = "Must Contain 8 Characters"
        }

        return errors
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
            SubmitForm()
        },
    })

    const SubmitForm = async () => {
        setLoading(true)
        axios.post(`${APIURL}/api/website/login`, {
            'AppKey': APIKEY,
            'email': DecryptedP != null ? CustomerEmail : formik.values.email,
            'password': formik.values.password,
        }).then(DataResponse => {
            const Response = DataResponse.data.response;
            if (Response.error === true && Response.errorMsg === "The provided credentials are incorrect.") {
                setCredentialsError(true)
            } else {
                setCustomerData(Response.data);
                setCustomerToken(Response.Token);
                localStorage.setItem('Token', Response.Token);
                console.log(Response.data)
                localStorage.setItem('Email', Response.data.email);
                localStorage.setItem('limitBalance', Response.data.limit_balance);
                localStorage.setItem('ID', Response.data.id);
                localStorage.setItem('RegistrationStatus', Response.data.registration_status)
                console.log(Response.data.registration_status)
                // navigate("/home");
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
            setLoading(false)
        });
    }


    if (localStorage.getItem('Token')) { //this is how I tried to redirect
        return <Navigate to="/home" />
    }
    return (
        <div className='flex flex-col items-center justify-around gap-4 bg-primary'>
            <div className="w-[8rem] h-[6rem] mt-4 flex justify-center items-center">
                <img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
            </div>
            <div className='flex flex-col items-center justify-around w-full gap-4 bg-white rounded-tr-3xl rounded-tl-3xl'>
                <div className="text-lg w-[70%] relative left-[-14px] font-[400] mt-6 -mb-2 ">Welcome</div>
                <div className="text-[1.8rem] w-[70%] relative left-[-14px] font-[500] mb-1 ">Let's sign you in</div>
                <form onSubmit={formik.handleSubmit} method="post" className='w-[80%] mt-2 flex flex-col gap-4 justify-around items-start'>
                    {CredentialsError === true ? (
                        <div className="w-full px-3 py-2 border border-red-400 rounded-lg bg-red-200/80">
                            <span className='mb-2 -mt-2 text-sm text-red-800'>
                                The provided credentials are incorrect
                            </span>
                        </div>
                    ) : ''}
                    <div className="flex flex-col w-full gap-2">
                        <div className="relative w-full">
                            <input type="email" id="email" title='email' name="email"
                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={DecryptedP != null ? CustomerEmail : formik.values.email}
                                className={`block rounded-lg  font-[600] px-2.5 pb-2.5  ${formik.touched.email && formik.values.email === '' ? (' border border-red-500') : ''}  pt-5 w-full text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="Email" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                E-mail
                            </label>
                        </div>
                        {formik.touched.email && formik.errors.email && (
                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.email}</span>
                        )}
                    </div>
                    <div className="flex flex-col w-full gap-2">
                        <div className="relative w-full">
                            <input
                                type={`${showPassword === true ? 'text' : 'password'}`} title="password" name="password" id="password"

                                onChange={formik.handleChange} onBlur={formik.handleBlur}
                                value={formik.values.password}
                                className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.password && formik.values.password === '' ? (' border border-red-500') : ''}  pt-5 w-full text-base font-[600] text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="password" className={`absolute text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                Password
                            </label>
                            <i onClick={() => {
                                setShowPassword(!showPassword)
                            }} className={`fa-light ${showPassword === true ? 'fa-eye' : 'fa-eye-low-vision'} mt-1 text-zinc-400 w-[5%] flex-none absolute top-[17px] right-[20px] `}></i>
                        </div>

                        {formik.touched.password && formik.errors.password && (
                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.password}</span>
                        )}
                    </div>

                    <div className="flex items-center justify-end w-full">
                        <Link to="/forgot-password" className="flex items-center mt-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            <span className="text-lg">Forgot Password ? </span>
                        </Link>
                    </div>
                    <div className="flex items-center w-full my-2">
                        <button onClick={() => {
                            SubmitForm()
                        }} type="submit" title="Signin" disabled={loading} className={`bg-primary text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''}  ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'}  rounded-xl px-3 py-2 w-full`}>
                            {loading === true ? '' : 'Sign in'}
                            {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                        </button>
                    </div>
                    <div className="flex items-center justify-center w-full mt-1 mb-4 text-lg">
                        <div className="text-gray-400">New to BEN SAAD ?</div>
                        <Link to="/email-otp" className="ml-1 text-primary">Signup</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Signin