import React from 'react'
import { Outlet, Link } from "react-router-dom";
import BottomBar from '../Components/BottomBar';
import Navbar from '../Components/Navbar';
import Signin from '../Pages/Signin/Signin';
const MainLayout = () => {
    return (
        <div className='relative flex flex-col justify-start gap-0 bg-primary'>
            <Navbar />
            <div className="flex items-center justify-between px-4 my-4 text-white">

                <div className="flex flex-col items-center justify-center ">
                    <Link to="/" className="w-[48px] h-[48px] bg-white   flex justify-center items-center rounded-lg">
                        <i className="text-3xl text-primary fa-light fa-circle-info"></i>
                    </Link>
                    <div className="text-white text-xs mt-[5px] mr-[0px]">Help</div>
                </div>

                <div className="flex flex-col items-center justify-center ">
                    <Link to="/" className="w-[48px] h-[48px] bg-white   flex justify-center items-center rounded-lg">
                        <i className="text-3xl text-primary fa-light fa-file-arrow-up"></i>
                    </Link>
                    <div className="text-white text-xs mt-[5px] mr-[0px]">Upload Doc's</div>
                </div>

                <div className="flex flex-col items-center justify-center ">
                    <Link to="/profile" className="w-[48px] h-[48px] bg-white   flex justify-center items-center rounded-lg">
                        <i className="text-3xl text-primary fa-light fa-user"></i>
                    </Link>
                    <div className="text-white text-xs mt-[5px] mr-[0px]">Profile</div>
                </div>

                <div className="flex flex-col items-center justify-center ">
                    <Link to="/" className="w-[48px] h-[48px] bg-white flex justify-center items-center rounded-lg">
                        <i className="text-3xl text-primary fa-light fa-gear"></i>
                    </Link>
                    <div className="text-white text-xs mt-[5px] mr-[0px]">Settings</div>
                </div>

            </div>
            <Outlet />
            <BottomBar />
        </div>
    )
}

export default MainLayout