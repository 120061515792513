import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';

import Beneficiaries from '../Components/Beneficiaries';
import Purpose from '../Components/Purpose';
import SourceOfFound from '../Components/SourceOfFound';
import MyListbox from '../../../../Components/MyListbox';
import DeliveryMode from '../Components/DeliveryMode';
import axios from 'axios';
import BeneficiariesV2 from '../Components/BeneficiariesV2';
const APIKEY = process.env.REACT_APP_API_KEY;
const APIURL = process.env.REACT_APP_API_URL;

const AccountInfo = ({ formData, setFormData, Errors }: any) => {
    const [enabled, setEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [BeneficiariesData, setBeneficiariesData] = useState<any>([])

    const getData = async () => {
        await axios.post(`${APIURL}/api/website/beneficiaries/get`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then((Response) => {
            setBeneficiariesData(Response.data.response.data);
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        getData()
    }, [formData.DeliveryMode])

    const validate = (values: any) => {
        let errors = {
            beneficiaryCountry: "",
            beneficiary: '',
            Beneficiary: '',
            PurposeOfTransaction: '',
            SourceOfFund: '',
            DeliveryMode: '',
        }

        if (!values.beneficiaryCountry) {
            errors.beneficiaryCountry = 'Beneficiary country is required'
        }

        if (!values.Beneficiary) {
            errors.Beneficiary = 'Beneficiary is required'
        }
        return errors
    }

    const formik = useFormik({
        initialValues: {
            beneficiary: '',
            beneficiaryCountry: '',
            PurposeOfTransaction: '',
            PurposeOfTransactionError: '',
            SourceOfFund: '',
            SourceOfFundError: '',
            DeliveryMode: '',
            DeliveryModeError: '',
            Beneficiary: '',
            BeneficiaryError: '',

        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
        },
    })
    return (
        <div>
            <div className='flex flex-col items-start justify-around w-full gap-5 rounded-xl'>
                <div className="text-xl relative w-full text-center font-[700] mt-2 mb-4 text-primary ">Transaction Information</div>
                <div className='flex flex-col items-start justify-around w-full gap-4 p-2 bg-white rounded-xl'>

                    <div className="relative w-full">
                        <BeneficiariesV2 label='Beneficiaries' formData={formData} setFormData={setFormData} Error={Errors[0].Beneficiary} Data={BeneficiariesData} />
                    </div>

                    <div className="w-full ">
                        <Purpose label='Purpose of transfer' formData={formData} setFormData={setFormData} Error={Errors[0].Purpose} />
                    </div>

                    <div className="w-full ">
                        <SourceOfFound label='Source of found' formData={formData} setFormData={setFormData} Error={Errors[0].SourceOfFound} />
                    </div>

                    <div className="w-full ">
                        <DeliveryMode label='Delivery mode' formData={formData} setFormData={setFormData} Error={Errors[0].DeliveryModeError} />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AccountInfo
