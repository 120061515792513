import * as fs from 'fs';
import axios from 'axios';
import React, { useState } from 'react'
import { useFormik } from 'formik';

const Documents = ({ formData, setFormData }: any) => {
	const APIURL = process.env.REACT_APP_API_URL;
	const APIKEY = process.env.REACT_APP_API_KEY;
	const [IDLoading, setIDLoading] = useState<any>(false);
	const [ProofLoading, setProofLoading] = useState(false);
	const [File2Loading, setFile2Loading] = useState(false);
	const [file, setFile] = useState<any>();
	const [file2, setFile2] = useState<any>();
	const [proofFile, setProofFile] = useState<any>();
	const [fileName, setFileName] = useState<any>();
	const [file2Name, setFile2Name] = useState<any>();
	const [proofFileName, setProofFileName] = useState<any>();

	const validate = (values: any) => {
		let errors = {
			file: "",
			file2: "",
			DocType: "",
			DocFile: "",
			ProofAddressDocType: "",
			ProofDocFile: "",
		}

		if (!values.file) {
			errors.file = 'This felid is required'
		}

		if (!values.DocType) {
			errors.DocType = 'Type is required'
		}

		if (!values.file2) {
			errors.file2 = 'This felid is required'
		}

		if (!values.DocFile) {
			errors.DocFile = 'This felid is required'
		}

		if (!values.ProofAddressDocType) {
			errors.ProofAddressDocType = 'This felid is required'
		}

		if (!values.ProofDocFile) {
			errors.ProofDocFile = 'This felid is required'
		}

		return errors
	}
	const formik = useFormik({
		initialValues: {
			DocType: "",
			file: "",
			file2: "",
			DocFile: "",
			ProofAddressDocType: "",
			ProofDocFile: "",
		},
		validate,
		onSubmit: (values) => {
		},
	})

	const UploadFile2 = async (file: any) => {
		setFile2Loading(true);

		const form = new FormData();
		form.append('file', file);
		form.append('AppKey', APIKEY || '');
		form.append('email', localStorage.getItem('Email') || '');
		await axios.post(`${APIURL}/api/upload/single/document`, form, {
			headers: { "Content-Type": "multipart/form-data" },
		}).then((Response) => {
			console.log(Response.data.response.data)
			setFile2Name(Response.data.response.data)
			setFormData({
				...formData,
				IDDocFile2Name: Response.data.response.data,
			});
			console.log(Response)
			setFile2Loading(false);
		}).catch((error) => {
			console.log(error)
		})
	}

	const UploadID = async (file: any) => {
		setIDLoading(true);

		const form = new FormData();
		form.append('file', file);
		form.append('AppKey', APIKEY || '');
		form.append('email', localStorage.getItem('Email') || '');

		await axios.post(`${APIURL}/api/upload/single/document`, form, {
			headers: { "Content-Type": "multipart/form-data" },
		}).then((Response) => {
			console.log(Response.data.response.data)
			setFileName(Response.data.response.data)
			setFormData({
				...formData,
				IDDocFileName: Response.data.response.data,
			});
			console.log(Response)
			setIDLoading(false);
		}).catch((error) => {
			console.log(error)
		})
	}

	const UploadProof = async (file: any) => {
		setProofLoading(true);
		const form = new FormData();
		form.append('file', file);
		form.append('AppKey', APIKEY || '');
		form.append('email', localStorage.getItem('Email') || '');

		await axios.post(`${APIURL}/api/upload/single/document`, form, {
			headers: { "Content-Type": "multipart/form-data" },
		}).then((Response) => {
			console.log(Response)
			setProofLoading(false);
			console.log(Response.data.response.data)
			setProofFileName(Response.data.response.data)
			setFormData({
				...formData,
				ProofDocFileName: Response.data.response.data,
			});
			setProofLoading(false);
		}).catch((error) => {
			console.log(error)
		})


	}

	function handleImage(e: any) {
		setFile(URL.createObjectURL(e.target.files[0]))
		UploadID(e.target.files[0])
	}

	function handleFile2(e: any) {
		setFile2(URL.createObjectURL(e.target.files[0]))
		UploadFile2(e.target.files[0])
	}

	function handleProofFile(e: any) {
		UploadProof(e.target.files[0])
		setProofFile(URL.createObjectURL(e.target.files[0]))
	}

	const removeSelectedImage = () => {
		setFile(null);
	};

	const removeSelectedProofFile = () => {
		setProofFile(null);
	};

	return (
		<div className='flex flex-col items-start justify-around w-full gap-2'>
			<div className="text-xl w-[70%] relative left-[-14px] font-[700] mt-6 mb-2 ml-4">Upload Documents</div>

			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="relative w-full">
					<select title='DocType' name="DocType" id="DocType"
						onChange={(e) => {
							formik.handleChange(e)
							setFormData({
								...formData,
								DocType: e.target.value,
							});
						}}
						onBlur={formik.handleBlur}

						value={formData.DocType}
						className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full ${formik.touched.DocType && formik.errors.DocType ? (' border border-red-500 text-red-500') : ''} text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} >
						<option value="">Select Type</option>
						<option value="Passport">Passport</option>
						<option value="EU_ID_Card">EU ID Card</option>
						<option value="Driving_license">Driving license</option>
						<option value="Uk_Resident_permit">UK Residence permit</option>
					</select>
					<label htmlFor="floating_filled" className={`absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
						Proof of ID Type
					</label>
				</div>
				{formik.touched.DocType && formik.errors.DocType && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.DocType}</span>
				)}
			</div>
			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="text-sm text-gray-400">Front side</div>
				<div className="flex items-center justify-center w-full h-[5rem] relative">
					<label htmlFor="dropzone-file" className={`relative flex flex-col ${file != null ? 'items-start pl-4' : 'items-center'} justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600`}>
						<div className="flex flex-col items-center justify-center pt-5 pb-6">
							<svg aria-hidden="true" className="w-8 h-8 mb-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
							<p className="mb-0 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
						</div>
						<input id="dropzone-file"
							onChange={(e) => {
								formik.handleChange(e)
								handleImage(e)
								setFormData({
									...formData,
									DocFile: e.target.files,
								});
							}}
							// value={formData.DocFile}
							onBlur={formik.handleBlur}

							type="file" className="hidden" name='IDFile' />
						{file != null ? (
							<div className="absolute top-[-6px] rounded-xl flex flex-col items-center justify-center w-[6rem] py-[5px] h-[5rem] w- gap-2 mt-1 right-2">
								<img src={file} alt="" className='w-full h-full rounded-xl' />
							</div>
						) : ''}
						{file != null ? (
							<button onClick={removeSelectedImage} className="mt-12 absolute top-[-37px] right-[24px]">
								<div className="hidden">icon</div>
								<i className="text-lg text-gray-400 fa-duotone fa-circle-xmark"></i>
							</button>
						) : ''}
						{IDLoading === true ? (
							<div className="absolute top-[-6px] rounded-xl flex bg-gray-100/60 flex-col items-center justify-center w-[6rem] py-[5px] h-[5rem] gap-2 mt-1 right-2">
								<i className="fa-solid fa-spinner-third animate-spin"></i>
							</div>
						) : ''}
					</label>
				</div>
				{formik.touched.DocFile && formik.errors.DocFile && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.DocFile}</span>
				)}
			</div>

			{file != null ? formData.DocType === 'EU_ID_Card' || formData.DocType === 'Uk_Resident_permit' ? (
				<div className="flex flex-col w-full gap-2 mt-1">
					<div className="text-sm text-gray-400">Back side</div>
					<div className="flex items-center justify-center w-full h-[5rem] relative">
						<label htmlFor="IDFileFaceTwo" className={`relative flex flex-col ${file != null ? 'items-start pl-4' : 'items-center'} justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600`}>
							<div className="flex flex-col items-center justify-center pt-5 pb-6">
								<svg aria-hidden="true" className="w-8 h-8 mb-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
								<p className="mb-0 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
							</div>
							<input id="IDFileFaceTwo"
								onChange={(e) => {
									formik.handleChange(e)
									handleFile2(e)
									setFormData({
										...formData,
										DocFile2: e.target.files,
									});
								}}
								// value={formData.DocFile}
								onBlur={formik.handleBlur}
								type="file" className="hidden" name='IDFileFaceTwo' />
							{file2 != null ? (
								<div className="absolute top-[-6px] rounded-xl flex flex-col items-center justify-center w-[6rem] py-[5px] h-[5rem] w- gap-2 mt-1 right-2">
									<img src={file2} alt="" className='w-full h-full rounded-xl' />
								</div>
							) : ''}
							{file2 != null ? (
								<button onClick={removeSelectedImage} className="mt-12 absolute top-[-37px] right-[24px]">
									<div className="hidden">icon</div>
									<i className="text-lg text-gray-400 fa-duotone fa-circle-xmark"></i>
								</button>
							) : ''}
							{File2Loading === true ? (
								<div className="absolute top-[-6px] rounded-xl flex bg-gray-100/60 flex-col items-center justify-center w-[6rem] py-[5px] h-[5rem] gap-2 mt-1 right-2">
									<i className="fa-solid fa-spinner-third animate-spin"></i>
								</div>
							) : ''}
						</label>
					</div>
					{formik.touched.file2 && formik.errors.file2 && (
						<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.file2}</span>
					)}
				</div>
			) : '' : ''}

			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="relative w-full">
					<select title='ProofAddressDocType' name="ProofAddressDocType" id="ProofAddressDocType"
						onChange={(e) => {
							formik.handleChange(e)
							setFormData({
								...formData,
								ProofAddressDocType: e.target.value,
							});
						}}
						value={formData.ProofAddressDocType}
						onBlur={formik.handleBlur}
						className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full ${formik.touched.ProofAddressDocType && formik.errors.ProofAddressDocType ? (' border border-red-500 text-red-500') : ''} text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} >
						<option value="">Select Type</option>
						<option value="Bank_statement">Bank statement</option>
						<option value="Utility_Bill">Utility Bill</option>
						<option value="Driving_license">Driving license</option>
						<option value="Council_tax_bill">Council tax bill</option>
						<option value="Tenancy_Agreement">Tenancy Agreement</option>
					</select>
					<label htmlFor="floating_filled" className={`absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
						Proof of Address Doc's Type
					</label>
				</div>
				{formik.touched.ProofAddressDocType && formik.errors.ProofAddressDocType && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.ProofAddressDocType}</span>
				)}
			</div>

			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="flex items-center justify-center w-full h-[5rem] relative">
					<label htmlFor="ProofDocFile" className={`relative flex flex-col ${proofFile != null ? 'items-start pl-4' : 'items-center'} justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600`}>
						<div className="flex flex-col items-center justify-center pt-5 pb-6">
							<svg aria-hidden="true" className="w-8 h-8 mb-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
							<p className="mb-0 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
						</div>
						<input id="ProofDocFile"
							onChange={(e) => {
								formik.handleChange(e)
								handleProofFile(e)
								setFormData({
									...formData,
									ProofDocFile: e.target.files,
								});
							}}
							// value={formData.DocFile}
							onBlur={formik.handleBlur}
							type="file" className="hidden" name='ProfFile' />
						{proofFile != null ? (
							<div className="absolute top-[-6px] rounded-xl flex flex-col items-center justify-center w-[6rem] py-[5px] h-[5rem] gap-2 mt-1 right-2">
								<img src={proofFile} alt="" className='w-full h-full rounded-xl' />
							</div>
						) : ''}

					</label>
					{proofFile != null ? (
						<button onClick={removeSelectedProofFile} className="mt-12 absolute top-[-37px] right-[24px]">
							<div className="hidden">icon</div>
							<i className="text-lg text-gray-400 fa-duotone fa-circle-xmark"></i>
						</button>
					) : ''}

					{ProofLoading === true ? (
						<div className="absolute top-[-6px] rounded-xl flex bg-gray-100/60 flex-col items-center justify-center w-[6rem] py-[5px] h-[5rem] gap-2 mt-1 right-2">
							<i className="fa-solid fa-spinner-third animate-spin"></i>
						</div>
					) : ''}
				</div>
				{formik.touched.ProofDocFile && formik.errors.ProofDocFile && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.ProofDocFile}</span>
				)}

			</div>
		</div>
	)
}

export default Documents