import React, { useEffect, useState } from 'react'
import SingInImg from '../../../assets/images/icons/output-onlinepngtools (1).png';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from 'formik';
import axios from "axios";

const Email = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [CredentialsError, setCredentialsError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams('');
    const [OTPStatus, setOTPStatus] = useState<any>(null);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [ResendLoading, setResendLoading] = useState(false)


    const validate = (values: any) => {
        let errors = {
            email: "",
            OTP: "",
            password: '',
            confirmPassword: '',
        }

        if (!values.email) {
            errors.email = 'E-mail is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
        if (!values.OTP) {
            errors.OTP = 'OTP code is required'
        }

        if (!/(?=.{8,})/i.test(values.password)) {
            errors.password = 'Password field must be at least 8 Characters'
        } else if (!values.password) {
            errors.password = 'Password is required'
        }

        if (!/(?=.{8,})/i.test(values.confirmPassword)) {
            errors.confirmPassword = 'Confirm password field must be at least 8 Characters'
        } else if (values.confirmPassword != values.password) {
            errors.confirmPassword = 'Confirm Password must be same as Password'
        } else if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm password is required'
        }

        return errors
    }

    useEffect(() => {
        // console.log(localStorage.getItem('Email'))
    }, [])

    const formik = useFormik({
        initialValues: {
            email: '',
            OTP: '',
            password: '',
            confirmPassword: '',
        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
            SubmitForm()
        },
    })

    const SubmitForm = async () => {
        setLoading(true)
    }

    const SendCode = async () => {
        setLoading(true)
        console.log(localStorage.getItem('Email'))
        axios.post(`${APIURL}/api/website/email`, {
            'AppKey': APIKEY,
            'email': localStorage.getItem('Email'),
        }).then(DataResponse => {
            const Response = DataResponse.data.message;
            console.log(Response)
            if (Response.error === true && Response.errorMsg === "User not found") {
                setCredentialsError(true)
            } else {
                setOTPStatus('CodeSent');
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
            setLoading(false)
        });
    }


    const CheckOTP = async () => {
        // await sleep(100);
        setLoading(true)
        let OTPCode = await formik.values.OTP;
        let Email = await localStorage.getItem('Email');
        console.log(OTPCode);

        await axios.post(`${APIURL}/api/email/code/check`, {
            'AppKey': APIKEY,
            'email': Email,
            'AuthCode': OTPCode,
        }).then(async (DataResponse) => {
            const Response = DataResponse.data.message;
            console.log(Response)
            if (Response.error === false) {
                setLoading(false);
                await setOTPStatus('CodeVerified');
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
            setLoading(false)
        });
    }

    const ResetPassword = async () => {
        // await sleep(100);
        setLoading(true)
        let OTPCode = await formik.values.OTP;
        let Email = await localStorage.getItem('Email');
        let Password = await formik.values.password;
        let ConfirmPassword = await formik.values.confirmPassword;
        console.log(Password);

        await axios.post(`${APIURL}/api/website/password/reset`, {
            'AppKey': APIKEY,
            'email': Email,
            'AuthCode': OTPCode,
            'password': ConfirmPassword,
        }).then(async (DataResponse) => {
            const Response = DataResponse.data.message;
            console.log(Response)
            if (Response.error === false) {
                setLoading(false);
                await setOTPStatus('PasswordRested');
                let EncryptedEmail = '';
                let EncryptedPassword = '';

                await axios.post(`${APIURL}/api/website/enc`, {
                    'Data': Email
                }).then((EmailResponse) => {
                    EncryptedEmail = EmailResponse.data.message.data
                })
                await axios.post(`${APIURL}/api/website/enc`, {
                    'Data': Password
                }).then((PasResponse) => {
                    EncryptedPassword = PasResponse.data.message.data
                })
                await setTimeout(async () => {
                    window.location.href = "/verification/phone?uem=" + EncryptedEmail + '&pas=' + EncryptedPassword;
                }, 4000)
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
            setLoading(false)
        });
    }

    return (
        <div className='flex flex-col items-center justify-around gap-4 bg-primary'>
            <div className="w-[10rem] h-[6rem] mt-4 flex justify-center items-center">
                <img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
            </div>
            <div className='flex flex-col items-center justify-around w-full gap-4 bg-white rounded-tr-3xl rounded-tl-3xl'>
                {OTPStatus === null ? (
                    <div className="text-[1.3rem] w-full flex justify-center font-[500] mb-1 mt-5">Enter Your E-mail</div>
                ) : OTPStatus === 'CodeSent' ? (
                    <div className="flex flex-col items-center justify-center mt-5 text-center">
                        <div className="text-[1.4rem] w-full font-[500] mb-1 ">Enter your code</div>
                        <div className="text-sm w-full font-[400] mb-1 ">We've sent a code to</div>
                        <div className="flex items-center justify-center w-full gap-2 mb-1 text-sm">
                            <div className="font-[700]">E-mail</div>
                            <div className="text-sm w-[34%] mt-[3px] mb-1 gap-2 flex justify-center items-center">
                                <div className="relative top-[3px] left-[6px] font-[700]">********</div>
                                <div className="font-[700]">{localStorage.getItem('Email')!.slice(-2)}</div>
                            </div>
                        </div>
                    </div>
                ) : OTPStatus === 'CodeVerified' ? (
                    <>
                        {/* <div className="text-lg w-[70%] relative left-[-14px] font-[400] mt-6 -mb-2 ">Welcome</div> */}
                        <div className="text-[1.3rem] w-[70%] relative left-[-14px] font-[500] mt-6 mb-1 ">Let's reset your password</div>
                    </>
                ) : ''}
                <form onSubmit={formik.handleSubmit} method="post" className='w-[80%] mt-2 flex flex-col gap-4 justify-around items-start'>
                    {CredentialsError === true ? (
                        <div className="w-full px-3 py-2 border border-red-400 rounded-lg bg-red-200/80">
                            <span className='mb-2 -mt-2 text-sm text-red-800'>
                                The E-mail is incorrect
                            </span>
                        </div>
                    ) : ''}

                    {OTPStatus === null ? (
                        <div className="flex flex-col w-full gap-2">
                            <div className="relative w-full">
                                <input type="email" id="email" title='email' name="email"
                                    readOnly
                                    value={localStorage.getItem('Email') || ''}
                                    className={`block rounded-lg  font-[600] px-2.5 pb-2.5 pt-5 w-full text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                                <label htmlFor="Email" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                    E-mail
                                </label>
                            </div>

                        </div>
                    ) : OTPStatus === 'CodeSent' ? (

                        <div className="flex flex-col w-full gap-2">


                            <div className="relative w-full">
                                <input type="number" id="OTP" title='OTP' name="OTP"
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    value={formik.values.OTP}
                                    className={`block rounded-lg  font-[600] px-2.5 pb-2.5  ${formik.touched.OTP && formik.values.OTP === '' ? (' border border-red-500') : ''}  pt-5 w-full text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                                <label htmlFor="OTP" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                    OTP Code
                                </label>
                            </div>
                            {formik.touched.OTP && formik.errors.OTP && (
                                <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.OTP}</span>
                            )}
                        </div>
                    ) : OTPStatus === 'CodeVerified' ? (
                        <>

                            <div className="flex flex-col w-full gap-2">
                                <div className="relative w-full">
                                    <input
                                        type={`${showPassword === true ? 'text' : 'password'}`} title="password" name="password" id="password"

                                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.password && formik.values.password === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                    <label htmlFor="password" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                        New password
                                    </label>
                                    <i onClick={() => {
                                        setShowPassword(!showPassword)
                                    }} className={`fa-light ${showPassword === true ? 'fa-eye' : 'fa-eye-low-vision'} mt-1 text-zinc-400 w-[5%] flex-none absolute top-[17px] right-[20px] `}></i>
                                </div>
                                {formik.touched.password && formik.errors.password && (
                                    <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.password}</span>
                                )}
                            </div>

                            <div className="flex flex-col w-full gap-2">
                                <div className="relative w-full">
                                    <input
                                        type={`${showConfirmPassword === true ? 'text' : 'password'}`} title="confirmPassword" name="confirmPassword" id="confirmPassword"

                                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        value={formik.values.confirmPassword}
                                        className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.confirmPassword && formik.values.confirmPassword === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                    <label htmlFor="password" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                        Confirm password
                                    </label>
                                    <i onClick={() => {
                                        setShowConfirmPassword(!showConfirmPassword)
                                    }} className={`fa-light ${showConfirmPassword === true ? 'fa-eye' : 'fa-eye-low-vision'} mt-1 text-zinc-400 w-[5%] flex-none absolute top-[17px] right-[20px] `}></i>
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.confirmPassword}</span>
                                )}
                            </div>
                        </>
                    ) : OTPStatus === 'PasswordRested' ? (

                        <div className="flex flex-col items-center justify-center w-full">
                            <div className="w-[200px] h-[200px] flex justify-center items-center ">
                                <i className="fa-duotone fa-circle-check text-[5.5rem] text-[#0bb60b]"></i>
                            </div>
                            <div className="text-lg w-full text-center font-[500] mb-1 ">The password has been changed successfully</div>
                        </div>
                    ) : ''}


                    <div className="flex items-center w-full my-2">
                        {OTPStatus === null ? (
                            <button onClick={() => {
                                setLoading(true);
                                SendCode()
                            }} type="button" title="Signin" disabled={loading} className={`bg-primary text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'}  rounded-xl px-3 py-2 w-full `}>
                                {loading === true ? '' : 'Submit'}
                                {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                            </button>
                        ) : OTPStatus === 'CodeSent' ? (
                            <button onClick={() => {
                                setLoading(true);
                                CheckOTP()
                            }} type="button" title="Signin" disabled={loading} className={`bg-primary text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'} rounded-xl px-3 py-2 w-full`}>
                                {loading === true ? '' : 'Verify E-mail'}
                                {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                            </button>
                        ) : OTPStatus === 'CodeVerified' ? (
                            <button onClick={() => {
                                setLoading(true);
                                ResetPassword()
                            }} type="button" title="Reset" disabled={loading} className={`bg-primary text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'} rounded-xl px-3 py-2 w-full`}>
                                {loading === true ? '' : 'Reset Password'}
                                {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                            </button>
                        ) : ''}
                    </div>

                    <div className="flex justify-center w-full ">
                        {OTPStatus === 'CodeSent' ? (
                            <button onClick={() => {
                                setResendLoading(true);
                                SendCode()
                            }} type="button" title="Resend code" disabled={ResendLoading} className={` text-gray-500 font-[400] text-base p-3`}>
                                {ResendLoading === true ? '' : 'Resend code'}
                                {ResendLoading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                            </button>
                        ) : ''}

                    </div>
                </form>

            </div>
        </div>
    )
}

export default Email
