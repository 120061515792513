import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../Components/Loading';

const Payment = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const [Error, setError] = useState(false)
    const navigate = useNavigate();

    const TID = localStorage.getItem('TID')
    const Transaction = JSON.parse(localStorage.getItem('Transaction') || '');
    const ErrorCode = searchParams.get('errorcode')
    const TrRef = searchParams.get('transactionreference')

    const CreateTransaction = async () => {
        await axios.post(`${APIURL}/api/website/transactions/create`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
            'Customer_id': localStorage.getItem('ID'),
            'Beneficiary_id': Transaction.BeneficiaryID,
            'Payment_method': "card",
            'tr_ref': TrRef,
            'Receive_currency': Transaction.ReceiveAmount,
            'Send_amount': Number(Transaction.SendAmount),
            'Current_rate': Transaction.Rate,
            'Delivery_mode': Transaction.DeliveryMode,
            'Total_send_amount': Transaction.SendAmount,
            'Total_receive_amount': Transaction.ReceiveAmount,
            'Total_to_pay': Transaction.SendAmount,
            'Purpose_of_transaction': Transaction.PurposeOfTransfer,
            'Source_of_fund': Transaction.SourceOfFound,
            'bank_name': Transaction.SelectedBank ?? null,
            'account_name': Transaction.AccountName ?? null,
            'account_number': Transaction.AccountNumber ?? null,
            'account_number_type': Transaction.AccountNumberType ?? null,
        }).then(DataResponse => {
            const Response = DataResponse.data.response;
            if (Response.error) {
                console.log(Response)

                localStorage.setItem('TRNID', Response.data.id);
                localStorage.setItem('TID', Response.data.TID);

                let NewBalance = Number(localStorage.getItem('limitBalance')) - Transaction.SendAmount
                localStorage.setItem('limitBalance', NewBalance.toString());

                setLoading(false)

            }
        }).finally(() => {
            setLoading(false)
        })

        global.setTimeout(() => {
            localStorage.removeItem('Transaction');
            navigate("/home");
        }, 5000)
    }
    useEffect(() => {
        if (localStorage.getItem('TRNID') != 'null' && localStorage.getItem('TID') != 'null') {
            CreateTransaction()
        } else {
            setError(true);
            setLoading(false)
        }
    }, [])
    return (
        <div className='flex flex-col justify-start gap-0 bg-gray-100 rounded-t-2xl'>
            {loading === true ? (
                <Loading />
            ) : Error === false ? (
                <div className="flex flex-col items-center justify-center w-full p-4">
                    <div className="flex flex-col items-center justify-center w-full py-2 mx-4 my-1 bg-white rounded-xl">
                        <div className="w-[200px] h-[200px] flex justify-center items-center ">
                            <i className="fa-duotone fa-circle-check text-[5.5rem] text-[#0bb60b]"></i>
                        </div>
                        <div className="text-lg w-full text-center font-[500] mb-1 ">Your payment has been successful</div>
                        <div className="text-sm w-full text-center font-[400] mb-1 text-gray-400 ">Transaction ID : {getUid(localStorage.getItem('ID'))}</div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center w-full">
                    <div className="w-[200px] h-[200px] flex justify-center items-center ">
                        <i className="fa-duotone fa-circle-xmark text-[5.5rem] text-red-600"></i>
                    </div>
                    <div className="text-lg w-full text-center font-[500] mb-1 ">Payment failed</div>
                </div>
            )}
        </div>
    )
}

export default Payment


function getUid(id: any) {
    if (id > 0 && id <= 9) {
        return 'BSTN00' + id;
    } else if (id > 9 && id <= 99) {
        return 'BSTN0' + id;
    } else if (id > 99) {
        return 'BSTN' + id;
    }
}