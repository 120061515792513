import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SingInImg from '../assets/images/icons/output-onlinepngtools (1).png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [ShowSideMenu, setShowSideMenu] = useState(false)
    let limitBalance2 = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
    }).format(Number('2520.00'));

    let limitBalance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
    }).format(Number(localStorage.getItem('limitBalance')));
    return (
        <div className="flex items-center justify-between px-4 py-2">
            {/* <Link to='/home'>
                <div className="w-[4rem] h-[3rem] flex justify-center items-center">
                    <img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
                </div>
            </Link> */}
            <div className="flex flex-col gap-1">
                <div className="text-3xl font-[600] text-white">{limitBalance}</div>
                <div className="text-xs font-[400] text-gray-300">Account limit</div>
            </div>
            <div className="flex items-center gap-4">
                <div className="w-[9px] h-[9px] rounded-full bg-primary flex items-center justify-center relative">
                    <i className="text-lg text-white fa-solid fa-bell"></i>
                    <div className="w-[10px] h-[10px] rounded-full bg-amber-600 absolute top-[-8px] right-[-6px] border-2 border-primary"></div>
                </div>
                <MyModal />
            </div>
        </div>
    )
}

export default Navbar

function MyModal() {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <>
            <div className="relative inset-0 flex items-center justify-center ">
                <button title="logout" onClick={openModal}
                    className="flex items-center justify-center w-12 h-12 rounded-full bg-primary">
                    <i className="text-3xl text-orange-400 fa-solid fa-power-off"></i>
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[9999999999999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        <i className="fa-duotone fa-right-from-bracket"></i> Exit
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to log out ?
                                        </p>
                                    </div>

                                    <div className="flex items-center justify-around mx-5 mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal()
                                                LogOut()
                                            }}
                                        >
                                            Yes
                                        </button>

                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 border border-transparent rounded-md bg-red-50 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal()
                                            }}
                                        >
                                            No
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
function LogOut() {
    localStorage.clear()
    window.location.reload();
    window.location.href = "/"
}