import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import Banks from '../Components/Banks';
import Relationship from '../Components/Relationship';
import AccountNumberType from '../Components/AccountNumberType';
const APIKEY = process.env.REACT_APP_API_KEY;
const APIURL = process.env.REACT_APP_API_URL;

const MoreInfo = ({ formData, setFormData }: any) => {
    const [enabled, setEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [BanksList, setBanksList] = useState<any>([])
    const getData = async () => {
        let Banks = '';

        await axios.post(`${APIURL}/api/website/bankslist`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then(async (response) => {
            Banks = response.data.response.data;
            setLoading(false)
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
        await setBanksList(Banks)
        console.log(Banks);
    }

    useEffect(() => {
        setLoading(true)
        console.log('Stared')
        getData()
    }, [])

    const validate = (values: any) => {
        let errors = {
            beneficiary: "",
            phone: '',
            AccountName: "",
            AccountNumber: "",
        }

        if (!values.beneficiary) {
            errors.beneficiary = 'Beneficiary is required'
        }

        if (!values.AccountName) {
            errors.AccountName = 'English name is required'
        }

        if (!values.phone) {
            errors.phone = 'Phone is required'
        }

        if (!values.AccountNumber) {
            errors.AccountNumber = 'Arabic name is required'
        }

        return errors
    }
    const formik = useFormik({
        initialValues: {
            AccountName: '',
            AccountNumber: '',
        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
        },
    })
    return (
        <div className='flex flex-col gap-5 justify-around items-start w-full'>
            <div className="text-xl relative left-[-14px] font-[700] mt-2 mb-4 ml-4 ">Bank Details & Relationship</div>

            <div className="w-full ">
                <Relationship label='Relationship' formData={formData} setFormData={setFormData} />
            </div>

            <div className="w-full ">
                <Banks label='Bank' formData={formData} setFormData={setFormData} Data={BanksList} />
            </div>

            <div className="w-full ">
                <div className="flex flex-col gap-2 w-full">
                    <div className="relative">
                        <input type="text" id="AccountName" title='AccountName' name="AccountName"
                            onChange={(e) => {
                                formik.handleChange(e)
                                setFormData({
                                    ...formData,
                                    AccountName: e.target.value,
                                });
                            }} onBlur={formik.handleBlur} value={formData.AccountName}
                            className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.AccountName && formik.errors.AccountName != '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                        <label htmlFor="AccountName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                            Account name
                        </label>
                    </div>
                    {formik.touched.AccountName && formik.errors.AccountName && (
                        <span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.AccountName}</span>
                    )}
                </div>
            </div>

            <div className="w-full ">
                <div className="flex flex-col gap-2 w-full">
                    <div className="relative">
                        <input type="text" id="AccountNumber" title='AccountNumber' name="AccountNumber"
                            onChange={(e) => {
                                formik.handleChange(e)
                                setFormData({
                                    ...formData,
                                    AccountNumber: e.target.value,
                                });
                            }} onBlur={formik.handleBlur} value={formData.AccountNumber}
                            className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.AccountNumber && formik.errors.AccountNumber != '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                        <label htmlFor="AccountNumber" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                            Account number
                        </label>
                    </div>
                    {formik.touched.AccountNumber && formik.errors.AccountNumber && (
                        <span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.AccountNumber}</span>
                    )}
                </div>
            </div>

            <div className="w-full ">
                <AccountNumberType label='Account number type' formData={formData} setFormData={setFormData} />
            </div>


        </div>
    )
}

export default MoreInfo
