import React, { useEffect, Fragment, useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import ReceiveCurrency from '../Components/ReceiveCurrency';
import { Dialog, Transition } from '@headlessui/react'
const APIKEY = process.env.REACT_APP_API_KEY;
const APIURL = process.env.REACT_APP_API_URL;

const AmountInfo = ({ formData, setFormData }: any) => {
    const [loading, setLoading] = useState(false)
    const [ReceiveAmount, setReceiveAmount] = useState<any>()
    const [TotalToPay, setTotalToPay] = useState<any>()
    const [LimitError, setLimitError] = useState<any>(false)
    const [LimitErrorMsg, setLimitErrorMsg] = useState<any>()
    const [isOpen, setIsOpen] = useState(false)
    const [limitBalance, setLimitBalance] = useState<any>(localStorage.getItem('limitBalance'))

    useEffect(() => {
        setFormData({
            ...formData,
            ReceiveCurrency: formData.beneficiaryCountry.currency_name,
            Rate: formData.beneficiaryCountry.online_rate,
        });
    }, [])


    const ReceiveAmountFormat = async (Number: any) => {
        setLoading(true)
        let Email = '';
        await axios.post(`${APIURL}/api/website/numbers`, {
            'number': Number
        }).then((Response) => {
            console.log(Response)
            setReceiveAmount(Response.data.message.data)
            setLoading(false)
        })

    }

    const CheckLimit = async (Amount: any) => {
        setLoading(true)
        if (Number(limitBalance) >= Number(Amount)) {
            setLimitError(false)
            setIsOpen(false)
            setFormData({
                ...formData,
                LimitError: false,
            });
        } else {
            setLimitError(true)
            setIsOpen(true)
            setFormData({
                ...formData,
                LimitError: true,
            });
        }
        setLoading(false)
    }

    const TotalToPayFormat = async (Number: any) => {
        setLoading(true)
        let Email = '';
        await axios.post(`${APIURL}/api/website/numbers`, {
            'number': Number
        }).then((Response) => {
            console.log(Response)
            setTotalToPay(Response.data.message.data)

            setLoading(false)
        })
    }
    const validate = (values: any) => {
        let errors = {
            SendAmount: "",
            ReceiveAmount: "",
            Rate: "",
            TotalToPay: "",
        }

        if (!values.SendAmount) {
            errors.SendAmount = 'Send amount is required'
        }

        if (!values.ReceiveAmount) {
            errors.ReceiveAmount = 'Receive amount is required'
        }

        return errors
    }
    const formik = useFormik({
        initialValues: {
            SendAmount: '',
            ReceiveAmount: 0.00,
            Rate: formData.beneficiaryCountry.online_rate,
            TotalToPay: 0.00,
        },
        validate,
        onSubmit: (values) => {
        },
    })

    const MyModal = () => {

        function closeModal() {
            setIsOpen(false)
        }

        function openModal() {
        }

        return (
            <>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal} >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-full p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-sm overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                                        <Dialog.Title
                                            as="div"
                                            className="flex items-center justify-center h-[5rem] text-lg font-medium leading-6 text-white bg-gray-100"
                                        >
                                            <i className="text-6xl text-yellow-300 fa-solid fa-triangle-exclamation"></i>
                                        </Dialog.Title>
                                        <div className="px-6 py-2 mt-2">
                                            <p className="text-sm text-gray-500">
                                                You have exceeded your transaction limit. <br />
                                                Please contact our office to resolve the issue
                                            </p>
                                        </div>

                                        <div className="flex items-center justify-center p-2 mx-5 mt-0">

                                            <button
                                                type="button"
                                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={() => {
                                                    closeModal()
                                                    // navigate("/sign-up");
                                                }} >
                                                Close
                                            </button>

                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </>
        )
    }

    return (
        <div className='flex flex-col items-start justify-around w-full gap-4'>

            <div className='flex flex-col items-start justify-around w-full gap-4 p-2 bg-white rounded-xl'>
                <div className="text-xl relative w-full text-center font-[700] mt-2 mb-4 text-primary">Transaction Details </div>

                <MyModal />

                <h3 className="mb-1 text-sm font-medium text-gray-900 dark:text-white">Selected currency</h3>
                <ul className="grid w-full grid-cols-2 gap-4 mb-2">
                    <li>
                        <input
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    ReceiveCurrency: e.target.value,
                                    Rate: formData.beneficiaryCountry.online_rate,
                                });
                            }}
                            type="radio" id="Cur1" name="ReceiveCurrency" value={formData.beneficiaryCountry.currency_name} className="hidden peer" required />
                        <label htmlFor="Cur1" className={`relative inline-flex ${formData.ReceiveCurrency === formData.beneficiaryCountry.currency_name ? 'border-primary' : 'border-gray-200'} items-center justify-between w-full p-3 text-gray-500 bg-white border  rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-white dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700`}>
                            <div className="block">
                                <div className="w-full text-lg font-semibold">{formData.beneficiaryCountry.currency_name}</div>
                                <div className="w-full text-xs">1.00 GBP = {formData.beneficiaryCountry.online_rate}</div>
                            </div>
                            {formData.ReceiveCurrency === formData.beneficiaryCountry.currency_name ? (
                                <div className=" absolute top-[3px] right-[3px]">
                                    <i className="w-6 h-6 ml-3 text-green-400 fa-solid fa-check-circle"></i>
                                </div>
                            ) : ''}
                        </label>
                    </li>
                    {formData.beneficiaryCountry.dollar_status === 1 ? (
                        <li>
                            <input
                                onChange={(e) => {

                                    setFormData({
                                        ...formData,
                                        ReceiveCurrency: e.target.value,
                                        Rate: formData.beneficiaryCountry.dollar_rate,
                                    });
                                }}
                                type="radio" id="Cur2" name="ReceiveCurrency" value="USD" className="hidden peer" />
                            <label htmlFor="Cur2" className="relative inline-flex items-center justify-between w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-white dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                <div className="block">
                                    <div className="w-full text-lg font-semibold">USD</div>
                                    <div className="w-full text-xs">1.00 GBP = {formData.beneficiaryCountry.dollar_rate}</div>
                                </div>
                                {formData.ReceiveCurrency === 'USD' ? (
                                    <div className=" absolute top-[3px] right-[3px]">
                                        <i className="w-6 h-6 ml-3 text-green-400 fa-solid fa-check-circle"></i>
                                    </div>
                                ) : ''}
                            </label>
                        </li>
                    ) : ''}
                </ul>
                <div className="flex items-baseline justify-between gap-2">
                    <div className="w-full my-2">
                        <div className="relative">
                            <div className=" absolute top-[22px] text-[13px] left-[14px]">
                                <i className="fa-regular fa-sterling-sign"></i>
                            </div>
                            <input type="text"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    let Amount: any = parseFloat(e.target.value) * formData.Rate
                                    // let SentAmount: number = parseFloat(e.target.value)
                                    let ReceivedAmount = new Intl.NumberFormat("en-US").format(Amount || 0);
                                    let Parsed = parseFloat(e.target.value);
                                    // let Sent = new Intl.NumberFormat("en-US").format(Parsed || 0);
                                    setFormData({
                                        ...formData,
                                        SendAmount: Parsed.toFixed(2) != 'NaN' ? Parsed.toFixed(2) : 0,
                                        ReceiveAmount: ReceivedAmount,
                                    });
                                }}

                                onBlur={() => { CheckLimit(formData.SendAmount) }}
                                // value={formData.SendAmount}
                                id="SendAmount" className={`block font-[500] pl-6 px-2.5 pb-3 ${formik.touched.SendAmount && formik.errors.SendAmount ? ('border-red-500 text-red-500') : 'border-gray-300'} pt-5 w-full text-sm text-gray-700 bg-transparent rounded-lg border appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="SendAmount" className="absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                Send amount
                            </label>
                        </div>
                        {formik.touched.SendAmount && formik.errors.SendAmount && (
                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.SendAmount}</span>
                        )}
                    </div>

                    <i className="text-lg fa-duotone fa-arrow-right-arrow-left text-primary"></i>

                    <div className="w-full my-2">
                        <div className="relative">
                            <div className=" absolute top-[22px] text-[13px] left-[14px]">
                                {formData.ReceiveCurrency === 'USD' ? (
                                    <i className="fa-regular fa-dollar-sign"></i>
                                ) : "SDG"}
                            </div>
                            <input type="text"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    let Received = e.target.value;
                                    let SentAmount: number = parseFloat(e.target.value) / formData.Rate
                                    console.log('Received : ' + Received)
                                    let Sent = new Intl.NumberFormat("en-US").format(SentAmount || 1);
                                    setFormData({
                                        ...formData,
                                        SendAmount: parseFloat(Sent).toFixed(2),
                                        ReceiveAmount: Number(parseFloat(Received))
                                    });
                                }}
                                onBlur={formik.handleBlur}
                                value={formData.ReceiveAmount}
                                id="ReceiveAmount" className={`block font-[500] ${formData.ReceiveCurrency === 'USD' ? 'pl-6' : 'pl-10'}  px-2.5 pb-3  ${formik.touched.ReceiveAmount && formik.errors.ReceiveAmount ? ('border-red-500 text-red-500') : 'border-gray-300'} pt-5 w-full text-sm text-gray-700 bg-transparent rounded-lg border  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="ReceiveAmount" className="absolute text-base text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                Receive amount
                            </label>
                        </div>
                        {formik.touched.ReceiveAmount && formik.errors.ReceiveAmount && (
                            <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.ReceiveAmount}</span>
                        )}
                    </div>
                </div>

            </div>

            <div className="relative flex flex-col items-end justify-end w-full p-4 bg-blue-50 rounded-xl">

                {loading === true ? (
                    <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-gray-200/90 z-[999] rounded-xl flex justify-center items-center">
                        <i className="text-4xl fa-solid fa-spinner-third text-primary animate-spin"></i>
                    </div>
                ) : ''}

                <div className="flex flex-col justify-end items-end gap-2 w-[80%]">

                    <div className="relative flex items-center justify-end w-full">
                        <div className="text-sm text-right absolute right-[138px]">Receiver gets :</div>
                        <div className="text-gray-600 ">{formData.ReceiveAmount || 0.00}</div>
                    </div>

                    <div className="h-px bg-gray-300 w-[90%] mx-auto absolute right-0 left-0 bottom-[49px] "></div>
                    <div className="relative flex items-center justify-end w-full mt-1">
                        <div className="text-lg font-[500] text-right absolute right-[138px]">Total :</div>
                        <div className="text-xl font-[500]">£{formData.SendAmount || 0.00}</div>
                    </div>

                </div>
            </div>
        </div>
    )
}



export default AmountInfo


