import axios from 'axios'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Nationality from '../Components/Nationality';
import Occupations from '../Components/Occupations';

const PersonalInfo = ({ formData, setFormData }: any) => {
	const APIKEY = process.env.REACT_APP_API_KEY;
	const APIURL = process.env.REACT_APP_API_URL;
	const [loading, setLoading] = useState(false)
	const [OccupationsLoading, setOccupationsLoading] = useState<any>(false)
	const [NationalitiesData, setNationalitiesData] = useState<any>([])
	const getNationalitiesData = async () => {
		await axios.post(`${APIURL}/api/website/countries`, {
			'AppKey': APIKEY,
			'token': localStorage.getItem('Token'),
		}).then((Response) => {
			setNationalitiesData(Response.data.response.data);
			setLoading(false)
		}).finally(() => {
			setLoading(false)
		})
	}

	useEffect(() => {
		setLoading(true)
		getNationalitiesData()
	}, [formData.DeliveryMode])
	const validate = (values: any) => {
		let errors = {
			fullName: "",
			arabicName: "",
			DateOfBirth: "",
			gender: "",
			occupation: "",
			nationality: "",
		}

		if (!formData.fullName) {
			errors.fullName = 'full name is required'
		}

		if (!formData.arabicName) {
			errors.arabicName = 'Arabic name is required'
		}

		if (!formData.DateOfBirth) {
			errors.DateOfBirth = 'Date Of Birth is required'
		}

		if (!formData.gender) {
			errors.gender = 'Gender is required'
		}

		if (!formData.occupation) {
			errors.occupation = 'Occupation is required'
		}

		if (!formData.nationality) {
			errors.nationality = 'Nationality is required'
		}
		return errors
	}

	const formik = useFormik({
		initialValues: {
			fullName: "",
			arabicName: "",
			DateOfBirth: "",
			gender: "",
			occupation: "",
			nationality: "",
		},
		validate,
		onSubmit: (values: any) => {
			alert(JSON.stringify(values, null, 2))
		},
	})

	return (
		<div className='flex flex-col items-start justify-around w-full gap-2'>
			<div className="text-xl w-[70%] relative left-[-14px] font-[700] mt-6 mb-2 ml-4">Personal Details</div>


			<div className="flex flex-col gap-2 w-full">
				<div className="relative w-full">
					<input type="text" id="fullName" title='fullName' name="fullName"
						onChange={(e) => {
							formik.handleChange(e)
							setFormData({
								...formData,
								fullName: e.target.value,
							});
						}}
						value={formData.fullName}
						onBlur={formik.handleBlur}
						className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.fullName && formik.values.fullName === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
					<label htmlFor="Email" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
						Full name
					</label>
				</div>
				{formik.touched.fullName && formik.errors.fullName && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.fullName}</span>
				)}
			</div>


			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="relative w-full">
					<input type="text" id="arabicName" title='arabicName' name="arabicName"
						onChange={(e) => {
							formik.handleChange(e)
							setFormData({
								...formData,
								arabicName: e.target.value,
							});
						}}
						value={formData.arabicName}
						onBlur={formik.handleBlur}
						className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.arabicName && formik.values.arabicName === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
					<label htmlFor="arabicName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
						Arabic name
					</label>
				</div>
				{formik.touched.arabicName && formik.errors.arabicName && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.arabicName}</span>
				)}
			</div>
			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="relative w-full">
					<input type="date" id="DateOfBirth" title='DateOfBirth' name="DateOfBirth"
						onChange={(e) => {
							formik.handleChange(e)
							setFormData({
								...formData,
								DateOfBirth: e.target.value,
							});
						}}
						value={formData.DateOfBirth}
						onBlur={formik.handleBlur}
						className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.DateOfBirth && formik.values.DateOfBirth === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
					<label htmlFor="DateOfBirth" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
						Date of birth
					</label>
				</div>
				{formik.touched.DateOfBirth && formik.errors.DateOfBirth && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.DateOfBirth}</span>
				)}
			</div>

			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="relative w-full">
					<select title='gender' name="gender" id="gender"
						onChange={(e) => {
							formik.handleChange(e)
							setFormData({
								...formData,
								gender: e.target.value,
							});
						}}
						value={formData.gender}
						onBlur={formik.handleBlur}
						className={`block rounded-lg px-2.5 pb-2.5 font-[600] ${formik.touched.gender && formik.values.gender === '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} >
						<option selected value="">Genders</option>
						<option value="Male">Male</option>
						<option value="Female">Female</option>
						<option value="Prefer_not_to_say">Prefer not to say</option>
					</select>
					<label htmlFor="gender" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
						Select gender
					</label>
				</div>
				{formik.touched.gender && formik.errors.gender && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.gender}</span>
				)}
			</div>

			<div className="flex flex-col w-full gap-2 mt-1">
				<Occupations label='Occupations' formData={formData} setFormData={setFormData} />
				{formik.touched.occupation && formik.errors.occupation && (
					<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.occupation}</span>
				)}

			</div>

			<div className="flex flex-col w-full gap-2 mt-1">
				<div className="relative w-full">
					<Nationality label='Nationality' formData={formData} setFormData={setFormData} Data={NationalitiesData} />
					{formik.touched.nationality && formik.errors.nationality && (
						<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.nationality}</span>
					)}
				</div>
			</div>

		</div>
	)
}

export default PersonalInfo