import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as htmlToImage from 'html-to-image';

const View = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [TransactionData, setTransactionData] = useState<any>([])
    const [sample, setSample] = useState<any>('')
    const { id } = useParams();

    const downloadImage = async (ID: any) => {
        var node = document.getElementById(ID);
        console.log(node)

        const dataUrl = await htmlToImage.toPng<any>(node);
        // download image
        const link = document.createElement("a");
        link.download = Date() + ".png";
        link.href = dataUrl;
        setSample(link.href)
        link.click();
    }

    const getData = async () => {
        let Response = '';
        axios.post(`${APIURL}/api/website/transactions/get/one`, {
            'AppKey': APIKEY,
            'TRID': id,
            'token': localStorage.getItem('Token'),
        }).then(async (response) => {
            Response = response.data.response.data;
            console.log(typeof Response)
            console.log(Response)
            await setTransactionData([Response])
        })
    }

    useEffect(() => {
        getData()
    }, [id])


    const TransactionsUI = TransactionData?.map((Transaction: any) => {
        let BgColor = GetBgColor(Transaction.status);
        let TextColor = GetTextColor(Transaction.status);
        let BorderColor = GetBorderColor(Transaction.status);
        let Icon = GetIcon(Transaction.status);
        let TUID = getUid(Transaction.TID);
        let TotalToPay = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
        }).format(Transaction.Total_to_pay);
        const TRDate = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
        }).format(Date.parse(Transaction.Transaction_date));
        return (
            <div className="flex flex-col justify-between gap-3 mt-1">
                <div key={Transaction.id} id={Transaction.id} className="flex flex-col gap-3 p-4 bg-gray-100 rounded-xl">

                    <div className="flex flex-col gap-5 p-4 bg-white rounded-xl">
                        <div className="flex flex-col">
                            <div className="text-sm text-gray-400">Date</div>
                            <div className="text-base text-gray-800">{TRDate}</div>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex flex-col">
                                <div className="text-sm text-gray-400">TRN ID</div>
                                <div className="text-base text-gray-800">{TUID}</div>
                            </div>
                            <div className="flex flex-col">
                                <div className="text-sm text-gray-400">Receiver name</div>
                                <div className="text-base text-gray-800">{Transaction.beneficiaries.English_name}</div>
                                {/* <div className="text-xs text-gray-800">+{Transaction.beneficiaries.Phone}</div> */}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-5 p-4 bg-white rounded-xl">
                        <div className="flex flex-col">
                            <div className="text-sm text-gray-400">Destination</div>
                            <div className="text-base text-gray-800">{Transaction.beneficiaries.countries.name}</div>
                        </div>

                        <div className="flex justify-between">

                            <div className="flex flex-col">
                                <div className="text-sm text-gray-400">Total Received</div>
                                <div className="flex text-base text-gray-800">
                                    {Transaction.Receive_currency === 'USD' ? (
                                        <div className="mr-1 text-sm text-gray-500 fa-regular fa-dollar-sign">$</div>
                                    ) : ""}
                                    <div className="text-lg">
                                        {Transaction.Total_receive_amount}
                                    </div>
                                    <div className="mt-2 ml-1 text-xs text-gray-400">
                                        {Transaction.Receive_currency === 'USD' ? "" : "SDG "}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <div className="text-sm text-gray-400">Total Paid</div>
                                <div className="text-lg text-gray-800">
                                    {TotalToPay}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="mx-4 ">
                    <div className="flex items-center justify-end gap-4 p-4 bg-white rounded-xl">
                        <button title='share' onClick={() => { downloadImage(Transaction.id) }} className={`w-10 h-10 bg-green-100 rounded-xl flex justify-center items-center`}>
                            <i className={`fa-thin fa-share-nodes text-green-600 text-2xl`}></i>
                        </button>
                        <button title='share' onClick={() => { downloadImage(Transaction.id) }} className={`w-10 h-10 bg-blue-100 rounded-xl flex justify-center items-center`}>
                            <i className={`fa-thin fa-paper-plane text-blue-600 text-2xl`}></i>
                        </button>
                    </div>
                </div>

            </div>
        )
    });

    return (
        <div className='flex flex-col justify-start gap-0 bg-gray-100 rounded-t-2xl '>
            <div className="text-xl relative w-full text-center font-[700] mt-4 mb-4 text-primary">Transaction Details</div>

            {TransactionsUI}

        </div>
    )
}

export default View



function GetBgColor(status: any) {
    switch (status) {
        case 'ready':
            return 'bg-blue-200';
        case 'paid':
            return 'bg-green-200';
        case "Invalid":
            return 'bg-red-200';
        case "Processing":
            return 'bg-yellow-200';
        default:
            return 'bg-blue-200';
            break;
    }
}

function GetTextColor(status: any) {
    switch (status) {
        case 'ready':
            return 'text-blue-600';
        case 'paid':
            return 'text-green-400';
        case 'Invalid':
            return 'text-red-600';
        case "Processing":
            return 'text-yellow-600';
        default:
            return 'text-blue-600';
            break;
    }
}

function GetBorderColor(status: any) {
    switch (status) {
        case 'ready':
            return 'border-blue-600';
        case 'paid':
            return 'border-green-600';
        case 'Invalid':
            return 'border-red-600';
        case "Processing":
            return 'border-yellow-300';
        default:
            return 'border-blue-600';
            break;
    }
}


function GetIcon(status: any) {
    switch (status) {
        case 'ready':
            return 'circle-check';
        case 'paid':
            return 'circle-check';
        case 'Invalid':
            return 'circle-xmark';
        case "Processing":
            return 'hourglass-clock';
        default:
            return 'circle-check';
            break;
    }
}


function getUid(id: any) {
    if (id > 0 && id <= 9) {
        return 'BSTN00' + id;
    } else if (id > 9 && id <= 99) {
        return 'BSTN0' + id;
    } else if (id > 99) {
        return 'BSTN' + id;
    }
}

