import React, { useEffect, useState } from 'react'
import SumsubWebSdk from '@sumsub/websdk-react'

import axios from 'axios';

const SumSub = ({ formData, setFormData }: any) => {
    const applicantEmail = "";
    const applicantPhone = "";

    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [SumSub, setSumSub] = useState<any>('')
    const [ReviewStatus, setReviewStatus] = useState<any>('')
    const [accessToken, setAccessToken] = useState<any>(localStorage.getItem('SumSubAccessToken'))
    const [ApplicantId, setApplicantId] = useState<any>(localStorage.getItem('SumSubApplicantId'))
    const [SumSubUserId, setSumSubUserId] = useState<any>(localStorage.getItem('SumSubUserId'))
    console.log(formData)
    return (
        <div className='flex flex-col items-start justify-around w-full gap-2'>
            <div className="text-xl w-[70%] relative left-[-14px] font-[700] mt-6 mb-2 ml-4">ID Verification</div>
            <div id="sumsub-websdk-container"></div>

            <SumsubWebSdk
                accessToken={accessToken}
                // updateAccessToken={() => Promise.resolve(accessToken)}
                expirationHandler={() => Promise.resolve(accessToken)}
                config={{
                    lang: "ru-RU",
                    email: applicantEmail,
                    phone: applicantPhone,
                    i18n: {
                        document: {
                            subTitles: {
                                IDENTITY: "Upload a document that proves your identity"
                            }
                        }
                    },

                    uiConf: {
                        customCssStr:
                            ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
                    },

                }}
                options={{ addViewportTag: false, adaptIframeHeight: true }}
                onMessage={(type: any, payload: any) => {
                    console.log("onMessage", type, payload);
                    setReviewStatus(payload.reviewStatus)
                    setFormData({
                        ...formData,
                        ReviewStatus: payload.reviewStatus,
                    });
                }}
                onError={(data: any) => console.log("onError", data)}
            ></SumsubWebSdk>
        </div>
    )
}

export default SumSub
