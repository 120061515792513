import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useFormik } from 'formik';
const Data = [
    { Relation: "Brother" },
    { Relation: "Friend" },
];
const Relationship = ({ label, formData, setFormData }: any) => {
    const [selected, setSelected] = useState(Data[0])
    const [Error, setError] = useState(false)

    return (
        <div className="flex flex-col w-full">
            <Listbox value={selected}
                onChange={(e) => {
                    setFormData({
                        ...formData,
                        Relationship: e.Relation,
                    });
                    setSelected(e)
                }}
            >
                <div className="relative mt-1">
                    <Listbox.Button className="relative z-[999] InputBtn w-full h-14 group peer cursor-default rounded-lg bg-white py-3 pl-3 pr-10 text-left border-2 border-gray-300 hover:border-2 hover:border-primary focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block Input-label peer-focus:text-red-600 group-hover:text-primary  truncate bg-white absolute text-sm  top-[-12px] px-[6px]">{label}</span>
                        <span className="block truncate bg-white ">{formData.Relationship || 'Select '}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            {Data.length === 0 ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            )}
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 z-[99999999999] max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {Data.map((person: any, personIdx: any) => (
                                <Listbox.Option
                                    key={personIdx}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-3 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-gray-900'
                                        }`
                                    }
                                    value={person}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {person.Relation}
                                            </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                                                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                                    <i className="fa-duotone fa-circle-check"></i>
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div >
    )
}

export default Relationship