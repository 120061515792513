import React from 'react'

const Loading = () => {
    return (
        <div className="w-full flex justify-center items-center">
            <div className="shadow m-3 w-16 h-16 rounded-xl flex justify-center items-center text-2xl">
                <i className="fa-solid fa-spinner-third animate-spin"></i>
            </div>
        </div>
    )
}

export default Loading
