import React from 'react'
import {
    Formiz,
    FormizStep, // Import the FormizStep component
    useForm,
} from '@formiz/core'
import { isEmail } from '@formiz/validations'
import { MyField } from './Components/MyField'
import Beneficiaries from './Components/Beneficiaries'

const CreateV2 = () => {
    const myForm = useForm()
    const [isLoading, setIsLoading] = React.useState(false)

    const handleSubmit = (values: any) => {
        console.log(values) // Retrieves values after submit
    }

    const submitForm = (values: any) => {
        setIsLoading(true)

        setTimeout(() => {
            setIsLoading(false)
            alert(JSON.stringify(values))
            myForm.invalidateFields({
                email: 'You can display an error after an API call',
            })
            const step = myForm.getFieldStepName('step2') || ''
            myForm.goToStep(step)
        }, 1000)
    }

    return (
        <div className='flex flex-col gap-0 justify-start bg-white rounded-t-2xl'>
            <div className="p-3">
                <Formiz connect={myForm}
                    onValidSubmit={handleSubmit} >
                    <form noValidate onSubmit={myForm.submitStep} >
                        <FormizStep name="step1" >
                            <div className="flex flex-col gap-4">
                                {/* <Beneficiaries /> */}
                                <MyField name="firstName" label="First Name"
                                    required="First Name is required" />
                                <MyField name="lastName" label="Last Name"
                                    required="Last Name is required" />
                            </div>
                        </FormizStep>

                        <FormizStep
                            name="step2" // Split the form with FormizStep
                        >
                            <MyField
                                name="email"
                                label="Email"
                                validations={[
                                    {
                                        rule: isEmail(),
                                        message: 'This is not a valid email',
                                    },
                                ]}
                            />
                        </FormizStep>

                        <div className="text-sm text-gray-500 p-2 text-center w-full xs:w-auto order-first xs:order-none">
                            Step
                            {' '}
                            {myForm.currentStep && myForm.currentStep.index + 1 || 0}
                            {' '}
                            of
                            {' '}
                            {/* {myForm.steps.length} */}
                            3
                        </div>


                        {!myForm.isFirstStep && (
                            <button type="button" onClick={myForm.prevStep}>
                                Back
                            </button>
                        )}
                        {myForm.isLastStep ? (
                            <button type="submit" disabled={!myForm.isValid}>
                                Submit
                            </button>
                        ) : (
                            <button type="submit" disabled={!myForm.isStepValid}>
                                Continue
                            </button>
                        )}
                    </form>
                </Formiz>
            </div>
        </div>
    )
}

export default CreateV2