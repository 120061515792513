import React from 'react'
import { NavLink } from 'react-router-dom';
const BottomBar = () => {
    return (
        <div className="fixed h-full bg-gray-100">
            <div style={{ boxShadow: "2px 5px 10px 0px #00000080", }} className='px-2 flex justify-between fixed items-center bg-white rounded-t-2xl h-[5rem] bottom-0 right-0 left-0'>
                <NavLink to='/home' className={({ isActive }) => (isActive ? 'bg-primary rounded-xl text-white' : 'text-gray-500 ') + " text-[11px] flex flex-col justify-center items-center px-4 py-2 "}>
                    <div className="">
                        <i className="text-xl fa-light fa-home"></i>
                    </div>
                    Home
                </NavLink>
                <NavLink to='/transactions-history' className={({ isActive }) => (isActive ? 'bg-primary rounded-xl  text-white' : 'text-gray-500 ') + " text-[11px] flex flex-col justify-center items-center px-4 py-2 "}>
                    <div className="">
                        <i className="text-xl fa-light fa-rectangle-history"></i>
                    </div>
                    History
                </NavLink>
                <NavLink to='/send-money' className={({ isActive }) => (isActive ? 'bg-primary rounded-xl text-white' : 'text-gray-500 ') + " text-[11px] flex flex-col justify-center items-center px-4 py-2 "}>
                    <div className="">
                        <i className="text-xl fa-light fa-plus-circle"></i>
                    </div>
                    Send
                </NavLink>
                <NavLink to='/receivers' className={({ isActive }) => (isActive ? 'bg-primary rounded-xl text-white' : 'text-gray-500 ') + " text-[11px] flex flex-col justify-center items-center px-4 py-2 "}>
                    <div className="">
                        <i className="text-xl fa-light fa-users"></i>
                    </div>
                    Receivers
                </NavLink>
                <NavLink to='/calculator' className={({ isActive }) => (isActive ? 'bg-primary rounded-xl text-white' : 'text-gray-500 ') + " text-[11px] flex flex-col justify-center items-center px-4 py-2 pl-0 "}>
                    <div className="">
                        <i className="text-xl fa-light fa-calculator"></i>
                    </div>
                    Calculator
                </NavLink>
            </div>
        </div>
    )
}

export default BottomBar