import { Fragment, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useFormik } from 'formik';
import { Combobox, Transition } from '@headlessui/react'

const SourceOfFoundData = [
    { name: "Salary" },
    { name: "Business" },
    { name: "Saving" },
    { name: "Donation" },
    { name: "Gift" },
    { name: "Bank" },
    { name: "Lottery" }
]

export default function SourceOfFound({ label, formData, setFormData, Error }: any) {
    const [selected, setSelected] = useState(SourceOfFoundData[0])
    const [query, setQuery] = useState('')

    const filteredPeople =
        query === ''
            ? SourceOfFoundData
            : SourceOfFoundData.filter((country: any) =>
                country.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div className="flex flex-col w-full">
            <Combobox as="div" value={formData.SourceOfFound} onChange={(e: any) => {
                console.log('started...')
                console.log(e)
                setSelected(e)
                setFormData({
                    ...formData,
                    SourceOfFound: e.name,
                })

            }}

            >
                <div className="relative mt-1">
                    <div className="relative z-[999] InputBtn w-full h-14 group peer cursor-default rounded-lg bg-white py-3 pl-3 pr-10 text-left border border-gray-300 hover:border hover:border-primary focus:outline-none focus-visible:border-primary focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">{label}</span>
                        <Combobox.Input
                            className="w-[90%] border-none p-0 text-sm leading-5 font-[500] text-gray-900 focus:ring-0 absolute bottom-[13px] left-[11px]"
                            displayValue={formData.SourceOfFound}
                            onChange={(event) => {
                                setQuery(event.target.value)
                            }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            {SourceOfFoundData.length === 0 ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (
                                <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            )}
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute z-[9999] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredPeople.length === 0 && query !== '' ? (
                                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredPeople!.map((person: any) => (
                                    <Combobox.Option
                                        key={person.name}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={person}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {person.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary'
                                                            }`}
                                                    >
                                                        <i className="fa-duotone fa-circle-check"></i>
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>

            {formData.SourceOfFoundError != '' ? (
                <span className='mt-2 mb-2 text-sm text-red-500'>{formData.SourceOfFoundError}</span>
            ) : ''}
        </div>
    )
}
