import React, { useState } from 'react'
import Navbar from '../../../Components/Navbar'
import { useFormik } from 'formik';
import AccountInfo from './Steps/AccountInfo';
import AmountInfo from './Steps/AmountInfo';
import BankDetails from './Steps/BankDetails';
import axios from 'axios';
import Preview from './Steps/Preview';

const CreateTransaction = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false)
    const [Errors, setErrors] = useState<any>([
        {
            Beneficiary: '',
            Purpose: '',
            SourceOfFound: '',
            DeliveryMode: '',
        }
    ])
    const [page, setPage] = useState(0);

    const validate = (values: any) => {
        let errors = {
            beneficiary: "",
        }

        if (!values.beneficiary) {
            errors.beneficiary = 'Beneficiary is required'
        }

        return errors
    }
    const formik = useFormik({
        initialValues: {
            beneficiary: '',
            beneficiaryCountry: '',
            PurposeOfTransaction: '',
            SourceOfFund: '',
            DeliveryMode: '',
            SelectedBank: '',
            AccountName: "",
            AccountNumber: '',
            AccountNumberType: "",
            SendAmount: '',
            ReceiveAmount: '',
            Rate: '',
            TotalToPay: '',
            PurposeOfTransactionError: '',
            SourceOfFundError: '',
            DeliveryModeError: '',
            BeneficiaryError: '',
            LimitError: false,

        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
            handleSubmit()
        },
    })

    const [formData, setFormData] = useState({
        beneficiary: '',
        Beneficiary: "",
        beneficiaryCountry: '',
        PurposeOfTransaction: '',
        PurposeOfTransfer: '',
        SourceOfFund: '',
        SourceOfFound: '',
        DeliveryMode: '',
        AccountName: "",
        AccountNumber: '',
        AccountNumberType: "",
        SelectedBank: '',
        SendAmount: '',
        ReceiveAmount: '',
        Rate: '',
        TotalToPay: '',
        PurposeOfTransactionError: '',
        SourceOfFoundError: '',
        DeliveryModeError: '',
        BeneficiaryError: '',
        LimitError: false,

    });

    function handleSubmit() {
        setLoading(true);
        if (page === 0) {
            if (formData.Beneficiary.length === 0) {
                setFormData({
                    ...formData,
                    BeneficiaryError: "Beneficiary is required"
                })
            } else if (formData.PurposeOfTransfer.length === 0) {
                setFormData({
                    ...formData,
                    PurposeOfTransactionError: "Purpose of transfer is required"
                })
            } else if (formData.SourceOfFound.length === 0) {
                setFormData({
                    ...formData,
                    SourceOfFoundError: "Source of fund is required"
                })
            } else if (formData.DeliveryMode.length === 0) {
                setFormData({
                    ...formData,
                    DeliveryModeError: "Delivery mode is required"
                })
            } else {
                if (formData.DeliveryMode === 'cash') {
                    setPage(page + 2);
                } else if (formData.DeliveryMode === 'bank') {
                    setPage(page + 1);
                }
            }

            setLoading(false);
        } else if (page === 1) {
            if (formData.AccountName != '' && formData.AccountNumber != '' && formData.SelectedBank != '') {
                setPage(page + 1);
            }
            setLoading(false);
        } else if (page === 2) {
            if (formData.SendAmount != '' && formData.ReceiveAmount != '') {
                setPage(page + 1);
            }
            setLoading(false);
        } else if (page === 3) {
            setPage(page + 1);
            setLoading(false);
        }
    }

    const SignUpForm = () => {
        switch (page) {
            case 0:
                return <AccountInfo formData={formData} setFormData={setFormData} Errors={Errors} />;
            case 1:
                return <BankDetails formData={formData} setFormData={setFormData} Errors={Errors} />;
            case 2:
                return <AmountInfo formData={formData} setFormData={setFormData} Errors={Errors} />;
            case 3:
                return <Preview formData={formData} setFormData={setFormData} Errors={Errors} />;
            default:
                return <AccountInfo formData={formData} setFormData={setFormData} Errors={Errors} />;
        }
    }

    return (
        <div className='relative flex flex-col justify-start gap-0 bg-gray-100 rounded-t-2xl'>
            <div className="p-3">
                <form onSubmit={formik.handleSubmit} method="post" className='flex flex-col items-start justify-center w-full gap-2 mt-0'>
                    <div className="w-full">
                        {SignUpForm()}
                    </div>

                    {page <= 2 ? (
                        <div className={`flex flex-col  bg-white p-3 rounded-xl items-center justify-between w-full ${page === 3 ? "mb-4 mt-1" : "my-2 mt-2 "}`}>
                            <button onClick={() => {
                                handleSubmit()
                            }} type="button" title="Signin" disabled={loading || formData.LimitError} className={`bg-gradient-to-r from-orange-600/50 via-orange-600/70 to-orange-600/90 w-full text-white ${loading === true || formData.LimitError === true ? 'bg-gray-200' : ''}  rounded-lg p-3 `}>

                                {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (
                                    page === 3 ? 'Confirm' : 'Continue'
                                )}
                            </button>
                        </div>
                    ) : ''}

                    {
                        page > 0 && <button onClick={() => {
                            if (page === 3) {
                                if (formData.DeliveryMode === 'cash') {
                                    setPage(page - 1)
                                } else {
                                    setPage(page - 2)
                                }
                            } else {
                                if (formData.DeliveryMode === 'cash') {
                                    setPage(page - 2)
                                } else {
                                    setPage(page - 1)
                                }
                            }

                        }} type="button" title="Signin" disabled={loading} className={` absolute top-[18px] text-xl left-[15px] px-[10px] py-[5px] rounded-xl`}>
                            {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : (<i className="fa-regular fa-chevron-left"></i>)}
                        </button>
                    }

                </form>
            </div>
        </div>
    )
}

export default CreateTransaction