import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import SingInImg from '../../../assets/images/icons/output-onlinepngtools (1).png';
import Loading from '../../../Components/Loading';
import { Link } from 'react-router-dom';
import { RWebShare } from "react-web-share";
import * as htmlToImage from 'html-to-image';
const History = () => {
    // const domEl = useRef(SingInImg);
    // const imageRef = useRef(SingInImg);
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false)
    const [Error, setError] = useState(false)
    const [FilterMenu, setFilterMenu] = useState(false)
    const [Transactions, setTransactions] = useState<any>([])
    const [TRNUID, setTRNUID] = useState<any>('')
    const [sample, setSample] = useState<any>('')

    // const Date = new Intl.DateTimeFormat('en-US', {
    //     month: 'short',
    //     day: 'numeric',
    // }).format(Date());
    const downloadImage = async (ID: any) => {
        var node = document.getElementById(ID);
        console.log(node)

        const dataUrl = await htmlToImage.toPng<any>(node);
        // download image
        const link = document.createElement("a");
        link.download = Date() + ".png";
        link.href = dataUrl;
        setSample(link.href)
        link.click();
    }


    useEffect(() => {
        setLoading(true)
        let Response: any = [];
        global.setTimeout(() => {
            axios.post(`${APIURL}/api/website/transactions/get`, {
                'AppKey': APIKEY,
                'token': localStorage.getItem('Token'),
            }).then(async (DataResponse) => {
                Response = DataResponse.data.response;
                await setTransactions(DataResponse.data.response.data);
                setLoading(false)
            }).finally(async () => {
                setLoading(false)
            })
        }, 1000)
    }, [])

    const TransactionsUI = Transactions.map((Transaction: any) => {
        let BgColor = GetBgColor(Transaction.status);
        let TextColor = GetTextColor(Transaction.status);
        let BorderColor = GetBorderColor(Transaction.status);
        let Icon = GetIcon(Transaction.status);
        let TUID = getUid(Transaction.TID);
        const TRDate = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
        }).format(Date.parse(Transaction.Transaction_date));
        return (
            <div key={Transaction.id} id={Transaction.id} className={`bg-white flex flex-col shadow rounded-lg p-4 border-l-4 ${BorderColor}`}>
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between gap-3">
                        <div className={`w-10 h-10 ${BgColor}  rounded-xl flex justify-center items-center`}>
                            <i className={`fa-duotone fa-${Icon} ${TextColor} text-xl`}></i>
                        </div>

                        <div className="flex flex-col items-start justify-end text-xs">
                            <div className="text-base">{Transaction.beneficiaries.English_name}</div>
                            <div className="text-sm text-gray-400 font-[500]">{TUID}</div>
                        </div>
                    </div>
                    <div className="flex flex-col items-end justify-end text-xs">
                        <div className="text-sm font-[500] text-gray-600">{Transaction.Total_to_pay} {Transaction.Sender_currency}</div>
                        <div className="">{TRDate}</div>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <div className={`text-xs ${TextColor} font-[500] ml-[2px] capitalize`}>
                        {Transaction.status}
                    </div>
                    <div className="flex items-center justify-end gap-2 mt-2">
                        {/* <button title='share' onClick={() => {
                            downloadImage(Transaction.id)
                        }} className={`w-6 h-6 bg-cyan-100  rounded-md flex justify-center items-center`}>
                            <i className={`fa-duotone fa-share-nodes text-cyan-600 text-sm`}></i>
                        </button> */}

                        <div className={`w-6 h-6 bg-teal-100  rounded-md flex justify-center items-center`}>
                            <i className={`fa-duotone fa-paper-plane text-teal-600 text-sm`}></i>
                        </div>
                        <Link to={`/transactions/${Transaction.id}`} className={`w-6 h-6 bg-indigo-100 rounded-md flex justify-center items-center`}>
                            <i className={`fa-duotone fa-eye text-indigo-600 text-sm`}></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    });




    return (
        <div className='flex flex-col justify-start gap-0 bg-white rounded-t-2xl '>
            {loading === true ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-4 p-3">
                    <div className="flex items-center justify-between my-4">
                        <div className="">
                            <div className={`w-8 h-8 rounded-md flex justify-center items-center`} onClick={() => setFilterMenu(true)}>
                                <i className={`fa-duotone fa-filter text-gray-400 text-lg`}></i>
                            </div>
                        </div>
                        <div className="">
                            <Link to="/send-money">
                                <div className={`w-8 h-8 bg-indigo-100 rounded-md flex justify-center items-center`}>
                                    <i className={`fa-duotone fa-plus text-blue-600 text-lg`}></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {TransactionsUI}
                </div>
            )}
            {FilterMenu === true ? (
                <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-black/30" >
                    <div className=" fixed bottom-0 w-full right-0 left-0 z-[99999999999999999] p-4 bg-white h-[73%] rounded-t-[21px] shadow-md flex flex-col" >
                        <div className="flex items-center justify-between mt-1">
                            <button onClick={() => setFilterMenu(false)} title="close" className={`w-8 h-8 bg-gray-100 rounded-md flex justify-center items-center`}>
                                <i className={`fa-duotone fa-circle-xmark text-gray-600 text-lg`}></i>
                            </button>
                            <button
                                onClick={() => {
                                    setFilterMenu(false)
                                }}
                                className="text-sm text-blue-500">Apply</button>
                        </div>
                        <div className="flex flex-col gap-4 mt-7">
                            <div className="flex flex-col gap-4">
                                <div className="text-lg font-[500]">Sort by</div>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Creation Date</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Beneficiary</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Status</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex flex-col gap-4">
                                <div className="text-lg font-[500]">Filter by</div>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Creation Date</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Beneficiary</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Status</div>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            ) : ''}
        </div>
    )
}

export default History


function GetBgColor(status: any) {
    switch (status) {
        case 'ready':
            return 'bg-blue-200';
        case 'paid':
            return 'bg-green-200';
        case "Invalid":
            return 'bg-red-200';
        case "Processing":
            return 'bg-yellow-200';
        default:
            return 'bg-blue-200';
            break;
    }
}

function GetTextColor(status: any) {
    switch (status) {
        case 'ready':
            return 'text-blue-600';
        case 'paid':
            return 'text-green-400';
        case 'Invalid':
            return 'text-red-600';
        case "Processing":
            return 'text-yellow-600';
        default:
            return 'text-blue-600';
            break;
    }
}

function GetBorderColor(status: any) {
    switch (status) {
        case 'ready':
            return 'border-blue-600';
        case 'paid':
            return 'border-green-600';
        case 'Invalid':
            return 'border-red-600';
        case "Processing":
            return 'border-yellow-300';
        default:
            return 'border-blue-600';
            break;
    }
}


function GetIcon(status: any) {
    switch (status) {
        case 'ready':
            return 'circle-check';
        case 'paid':
            return 'circle-check';
        case 'Invalid':
            return 'circle-xmark';
        case "Processing":
            return 'hourglass-clock';
        default:
            return 'circle-check';
            break;
    }
}


function getUid(id: any) {
    if (id > 0 && id <= 9) {
        return 'BSTN00' + id;
    } else if (id > 9 && id <= 99) {
        return 'BSTN0' + id;
    } else if (id > 99) {
        return 'BSTN' + id;
    }
}

