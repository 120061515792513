import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const Settings = () => {
    const navigate = useNavigate();

    return (
        <div className='relative flex flex-col items-center justify-around gap-4 bg-primary'>

            <div className="flex items-center justify-between w-full px-4 py-2">
                <button title='back' onClick={() => navigate(-1)} className="text-white ">
                    <i className="text-lg fa-light fa-chevron-left"></i>
                </button>
                <div className="text-base text-white">Settings</div>
                <div className="">
                    {/* <i className="text-lg text-white fa-light fa-gear"></i> */}
                </div>
            </div>

            <div className='flex flex-col justify-start w-full h-full gap-3 bg-gray-100 rounded-t-2xl'>
                <div className="flex items-center justify-between mx-4 mt-8 mb-0" >Settings</div>
                <div className="flex flex-col gap-0 mx-4 bg-white shadow-sm rounded-xl">
                    <Link to="/change/phone" className="flex items-start justify-between gap-2 p-3 border-b border-b-gray-100">
                        <div className={`w-10 p-4 h-10 bg-gray-100 rounded-xl flex justify-center items-center`}>
                            <i className={`fa-thin fa-mobile text-2xl`}></i>
                        </div>
                        <div className="flex flex-col items-start w-full ml-3">
                            <div className="text-sm text-blue-900 font-[500]">Change phone</div>
                            <div className="text-xs text-gray-400">You can change your phone</div>
                        </div>
                        <div className="text-sm text-blue-900 font-[500]">
                            <i className="text-base text-primary fa-solid fa-chevron-right"></i>
                        </div>
                    </Link>
                    <div className="flex items-start justify-between gap-2 p-3 border-b border-b-gray-100">
                        <div className={`w-10 p-4 h-10 bg-gray-100 rounded-xl flex justify-center items-center`}>
                            <i className={`fa-thin fa-envelope-circle-check text-2xl`}></i>
                        </div>
                        <div className="flex flex-col items-start w-full ml-3">
                            <div className="text-sm text-blue-900 font-[500]">Change E-mail</div>
                            <div className="text-xs text-gray-400">You can change your E-mail</div>
                        </div>
                        <div className="text-sm text-blue-900 font-[500]">
                            <i className="text-base text-primary fa-solid fa-chevron-right"></i>
                        </div>
                    </div>

                    <div className="flex items-start justify-between gap-2 p-3 border-b border-b-gray-100">
                        <div className={`w-10 p-4 h-10 bg-gray-100 rounded-xl flex justify-center items-center`}>
                            <i className={`fa-thin fa-lock text-2xl`}></i>
                        </div>
                        <div className="flex flex-col items-start w-full ml-3">
                            <div className="text-sm text-blue-900 font-[500]">Change password</div>
                            <div className="text-xs text-gray-400">You can change your password</div>
                        </div>
                        <div className="text-sm text-blue-900 font-[500]">
                            <i className="text-base text-primary fa-solid fa-chevron-right"></i>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Settings