import React, { useState } from 'react'
import axios from 'axios';
import { Button, Tooltip } from 'flowbite-react';
import { useFormik } from 'formik';
// import { setTimeout } from 'timers/promises';

const PostCode = ({ formData, setFormData, onChange, value }: any) => {
	const sleep = (ms: any) => new Promise((r: any) => setTimeout(r, ms));

	const POSTCODE_APIKEY = process.env.REACT_APP_POSTCODE_APIKEY;
	const APIKEY = process.env.REACT_APP_API_KEY;
	const APIURL = process.env.REACT_APP_API_URL;
	const [showAddressesMenu, setShowAddressesMenu] = useState(false)
	const [SelectedAddress, setSelectedAddress] = useState<any>('')
	const [Addresses, setAddresses] = useState<any>([])
	const [AddressesLevelTwo, setAddressesLevelTwo] = useState<any>([])
	const [loadingLevelTwo, setLoadingLevelTwo] = useState(false)
	const [loading, setLoading] = useState(false)
	const [showFinalAddressesMenu, setShowFinalAddressesMenu] = useState(false)

	const ChangePostCode = (PostCode: any) => {
		setFormData({
			...formData,
			PostCode: PostCode,
		});

	}


	const validate = (values: any) => {
		let errors = {
			PostCode: "",
		}

		if (!formData.PostCode) {
			errors.PostCode = 'Post Code is required'
		}
		return errors
	}

	const formik = useFormik({
		initialValues: {
			PostCode: "",
		},
		validate,
		onSubmit: (values: any) => {
			alert(JSON.stringify(values, null, 2))
		},
	})

	const SearchPostCode = async () => {
		setLoading(true);

		await axios.get(`https://ws.postcoder.com/pcw/${POSTCODE_APIKEY}/address/UK/${formData.PostCode}?format=json`).then((Response) => {
			setAddresses(Response.data)
			setShowAddressesMenu(true);
			setLoading(false);
		}).finally(() => {
			setLoading(false);
		})

	}

	const SearchPostCodeLevelTwo = async (Address: any) => {
		setLoadingLevelTwo(true);
		await axios.get(`https://ws.postcoder.com/pcw/${POSTCODE_APIKEY}/address/UK/${Address}?format=json`).then((Response) => {
			setAddressesLevelTwo(Response.data)
			setShowFinalAddressesMenu(true);
			sleep(500)
			setLoadingLevelTwo(false);
		}).finally(() => {
			setLoadingLevelTwo(false);
		})
	}

	return (
		<div className='flex flex-col items-start justify-around w-full h-full gap-2'>

			<div className="text-xl w-[70%] left-[-14px] font-[700] mt-6 mb-2 ml-0">Your address</div>
			{showAddressesMenu === true ? (
				<div onClick={() => {
					setShowAddressesMenu(false)
				}} className="bg-gray-700/30 left-0 right-0 bottom-0 top-0 absolute w-full h-full z-[99999]"></div>
			) : ''}
			<div className="flex justify-between w-full z-[9999999] relative">
				<div className="flex flex-col gap-2 w-[89%] mt-1">
					<div className="relative w-full">
						<input type="text" id="fullName" title='fullName' name="fullName"
							onChange={(e) => {
								formik.handleChange(e)
								setFormData({
									...formData,
									PostCode: e.target.value,
								});
							}}
							onBlur={formik.handleBlur}
							value={formData.PostCode}
							className={`block rounded-lg px-2.5 pb-2.5  ${formik.errors.PostCode && (' border border-red-500')}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
						<label htmlFor="fullName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
							Postcode
						</label>
					</div>
					{formik.errors.PostCode && (
						<span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.PostCode}</span>
					)}
				</div>

				<button title='SearchPostcode' disabled={loading} type='button' onClick={SearchPostCode} className={`w-[4%] ${loading === true ? 'bg-gray-200' : 'bg-primary'} px-6 py-2 rounded-lg h-[3.2rem] mt-1 ml-2 flex justify-center items-center`}>
					{loading === true ? (<i className="text-white fa-solid fa-spinner-third animate-spin"></i>) : (<i className="text-white fa-solid fa-search"></i>)}
				</button>


				{showAddressesMenu === true ? (
					<div className="">
						<div className="absolute w-8 h-8 bg-white top-[66px] left-[36px] rotate-[46deg] z-[90] "></div>
						{showFinalAddressesMenu === true ? (
							<div className="z-[9999999] left-[-27px] right-0 bottom-0 top-[5rem] absolute w-[21rem] h-[20rem] overflow-y-scroll ">
								<div onClick={() => {
									setShowFinalAddressesMenu(false)
								}} className="flex items-center justify-center w-full h-12 bg-white rounded-tl-lg rounded-tr-lg">
									<div className="relative flex items-center justify-center w-[90%] h-full px-4 bg-gray-200 rounded-lg top-4">
										Back
									</div>
								</div>
								<div className="flex flex-col items-center justify-start gap-4 px-4 py-1 pt-8 bg-white rounded-bl-lg rounded-br-lg">
									{loading === true ? (
										<div className="w-12 h-12 bg-white rounded-xl">
											<i className="fa-solid fa-spinner-third animate-spin"></i>
										</div>
									) : (
										AddressesLevelTwo.map((Address: any) => {
											return (
												<div key={Address.summaryline}
													onClick={() => {
														setSelectedAddress(Address.summaryline)
														setFormData({
															...formData,
															SelectedAddress: Address.summaryline,
														});
														setShowFinalAddressesMenu(false)
														setShowAddressesMenu(false)
													}}
													className="flex justify-between w-full px-2 py-1">
													<div className="">{Address.summaryline}</div>
													<div className="">
														<i className="fa-solid fa-caret-right"></i>
													</div>
												</div>
											)
										})
									)}

								</div>
							</div>
						) : (
							<div className="bg-white rounded-lg px-4 py-1 z-[9999999] flex flex-col gap-4 justify-start items-center left-[-27px] right-0 bottom-0 top-[5rem] absolute w-[21rem] h-[20rem] overflow-y-scroll ">
								{Addresses.map((Address: any) => {
									return (
										<div key={Address.summaryline}
											onClick={() => {
												SearchPostCodeLevelTwo(Address.summaryline)
											}}
											className="flex justify-between w-full px-2 py-1">
											<div className="text-sm">{Address.summaryline}</div>
											<div className="">
												<i className="fa-solid fa-caret-right"></i>
											</div>
										</div>
									)
								})}
							</div>
						)
						}

					</div>
				) : ''}
			</div>

			{formData.SelectedAddress != '' ? (
				<div className="flex flex-col w-full gap-2 mt-1">
					<div className="relative w-full">
						<input type="text" id="SelectedAddress" title='SelectedAddress' name="SelectedAddress"
							onChange={(e) => {
								setFormData({
									...formData,
									SelectedAddress: SelectedAddress,
								});
							}}
							value={formData.SelectedAddress}
							className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
						<label htmlFor="fullName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
							Selected address
						</label>
					</div>
				</div>
			) : ''}
		</div>
	)
}

export default PostCode