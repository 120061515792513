import React, { useState } from 'react'
import { useField } from '@formiz/core'

// interface Props {
//     errorMessage: string,
//     id: number,
//     isValid: any,
//     isSubmitted: boolean,
//     setValue: any,
//     value: any,// 👈️ for demo purposes
// }

export const MyField = (props: any) => {
    const { errorMessage, id, isValid, isSubmitted, setValue, value, } = useField(props)
    const { label, type, required } = props
    const [isTouched, setIsTouched] = useState(false)
    const showError = !isValid && (isTouched || isSubmitted)

    return (
        <div className={`demo-form-group ${(showError) ? 'is-error' : ''}`}>
            <div className="flex flex-col gap-2 w-full">
                <div className="relative w-full">
                    <input
                        id={id}
                        type={type || 'text'}
                        value={value ?? ''}
                        className={`block rounded-lg  font-[600] px-2.5 pb-2.5 pt-5 w-full ${showError && 'border-red-500'} text-base text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`}
                        onChange={e => { setValue(e.target.value) }}
                        onBlur={() => { setIsTouched(true) }}
                    // aria-invalid={'false'}
                    // aria-required={required ?? required}
                    // aria-describedby={showError ? `${id}-esrror` : null}
                    />

                    <label htmlFor={id} className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-[18px] z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                        {label}
                    </label>
                </div>

                {showError && (
                    <div id={`${id}-error`} className="text-sm text-red-500 -mt-2 mb-2">
                        {errorMessage}
                    </div>
                )}
            </div>
        </div>
    )
}