import { useEffect, useState } from 'react'
import SingInImg from '../../../assets/images/icons/output-onlinepngtools (1).png';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from 'formik';
import axios from "axios";
// import Stringify from 'react-stringify'

const Phone = () => {
    const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [applicationVerifier, setApplicationVerifier] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [OTPLoading, setOTPLoading] = useState(false)
    const [CustomerPhone, setCustomerPhone] = useState<any>()
    const [CustomerEmail, setCustomerEmail] = useState<any>()
    const [CustomerStatus, setCustomerStatus] = useState<any>(null)
    const [confirmationResult, setConfirmationResult] = useState<any>()
    const [OTPStatus, setOTPStatus] = useState<any>(null);
    const [OTP, setOTP] = useState<any>('');
    const [CountryError, setCountryError] = useState<any>(null);
    const [PhoneError, setPhoneError] = useState<any>(null);
    const [OTPError, setOTPError] = useState<any>(null);
    const [CountryCode, setCountryCode] = useState<any>('');
    const [Countries, setCountries] = useState<any>([]);
    const [ip, setIP] = useState('');
    const [UserInfo, setUserInfo] = useState([]);
    const [UserData, setUserData] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams('');
    let { id } = useParams();
    const Decrypted = searchParams.get("uem");
    const Epas = searchParams.get("pas");

    //creating function to load ip address from the API
    const getData = async () => {

        const ip = await axios.get('https://geolocation-db.com/json/')
        const res = await axios.post('http://ip-api.com/batch', [
            { "query": ip.data.IPv4, "fields": "status,message,continent,continentCode,country,countryCode,region,regionName,city,district,zip,lat,lon,timezone,currency,isp,org,as,mobile,hosting,query", "lang": "en" }
        ])
        setIP(ip.data.IPv4)
        setUserInfo(res.data[0])

    }


    const navigate = useNavigate();
    const ph = '+299117493464';
    const lastTwo = ph.slice(-2);

    const validate = (values: any) => {

        let errors = {
            phone: "",
        }
        if (!values.phone) {
            errors.phone = 'Phone is required'
        }
        return errors
    }

    const formik = useFormik({
        initialValues: {
            phone: '',
            OTP: '',
            CountryCode: ''
        },
        validate,
        onSubmit: (values) => {
            SubmitForm()
        },
    })

    useEffect(() => {
        getData()
        axios.post(`${APIURL}/api/sender/countries`, {
            'AppKey': APIKEY,
        }).then(async (response) => {
            setCountries(response.data.message.data)
        }).catch(function (error) {
            console.log(error);
        })

        GetUserData()

    }, [])

    const GetUserData = async () => {
        // console.log(error);

        let Data = '';
        await axios.post(`${APIURL}/api/website/user/data`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then(async (response) => {
            console.log(response);
            Data = response.data.response.data
        }).catch(function (error) {
            console.log(error);
        })
        await setUserData(Data)

        console.log(Data);
    }

    const SubmitForm = async () => {
        setLoading(true)
    }

    function findCountry(fruit: any) {
        return fruit.name === "cherries";
    }

    const SendCode = async () => {
        await sleep(500);
        let PhoneNumber = await CountryCode + UserData.phone;
        await setOTPStatus('CodeSnet');
        await axios.post(`${APIURL}/api/website/phone/send`, {
            'AppKey': APIKEY,
            'phone': PhoneNumber,
            'UserData': UserInfo,
        }).then(async (response) => {
            await setOTPStatus('CodeSent');
            await setLoading(false);
            console.log(response);
        }).catch(function (error) {
            console.log(error);
            setOTPStatus('CodeSent');
            setLoading(false);
        })
        // setLoading(false);
    }

    const CheckOTP = async () => {
        let OTPCode = formik.values.OTP;
        let PhoneNumber = CountryCode + UserData.phone;
        const CountryInfo = await Countries.find(({ phone_code }: any) =>
            phone_code === '+' + CountryCode
        );

        await axios.post(`${APIURL}/api/website/phone/check`, {
            'AppKey': APIKEY,
            'OTPCode': OTPCode,
            "phone": PhoneNumber
        }).then(async (response) => {
            setCustomerStatus(true)
            // console.log(response)
            // setOTPStatus('CodeVerified');
            let EncryptedPhoneNumber = '';
            let Email = '';
            let Password = '';
            if (response.data.message.error === false) {
                await axios.post(`${APIURL}/api/website/enc`, {
                    'Data': PhoneNumber
                }).then((EmailResponse) => {
                    // console.log(EmailResponse)
                    EncryptedPhoneNumber = EmailResponse.data.message.data
                })

                await axios.post(`${APIURL}/api/website/dec`, {
                    'Data': Decrypted
                }).then((Response) => {
                    Email = Response.data.message.data
                    // console.log(Response)
                })

                // localStorage.setItem('RegistrationStatus', Response.data.registration_status)
                setTimeout(() => {
                    setOTPLoading(false);
                }, 1000)
                if (response.data.message.errorMsg === "Phone verified successfully") {
                    await axios.post(`${APIURL}/api/website/customer/verify`, {
                        'AppKey': APIKEY,
                        'email': Email
                    }).then(async (Response) => {
                        if (Response.data.response.data === "Customer verified") {
                            await setOTPStatus('CodeVerified');
                            await setTimeout(() => {
                                localStorage.setItem('RegistrationStatus', 'complete')
                                window.location.href = "/home";
                            }, 3000)
                        }
                    })

                }

            }

        }).catch(function (error) {
            console.log(error);
            setOTPLoading(false);
        })

    }

    return (
        <div className='flex flex-col items-center justify-around gap-4 bg-primary'>
            <div className="w-[10rem] h-[6rem] mt-4 flex justify-center items-center">
                <img className='w-full h-full ' src={SingInImg} alt={SingInImg} />
            </div>
            <div className='flex flex-col items-center justify-around w-full gap-4 p-4 bg-gray-100 rounded-tr-3xl rounded-tl-3xl'>
                <div className='flex flex-col items-center justify-around w-full gap-4 py-4 bg-white rounded-2xl'>

                    {OTPStatus === null ? (
                        <div className="text-[1.3rem] w-full flex justify-center  relative items-center font-[500] mb-1 mt-5 text-primary">Your mobile number</div>
                    ) : OTPStatus === 'CodeSent' ? (
                        <div className="flex flex-col items-center justify-center mt-5 text-center">
                            <div className="text-[1.3rem] w-full font-[500] mb-1 text-primary ">Enter your code</div>
                            <div className="text-sm w-full font-[400] mb-1 ">We've sent a code to</div>
                            <div className="flex items-center justify-center w-full gap-2 mb-1 text-sm">
                                <div className="font-[700]">Mobile</div>
                                <div className="text-sm w-[34%] mt-[3px] mb-1 gap-2 flex justify-center items-center">
                                    <div className="relative top-[3px] left-[6px] font-[700]">********</div>
                                    <div className="font-[700]">{UserData.phone.toString().slice(-2)}</div>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    <form onSubmit={formik.handleSubmit} method="post" className='w-[90%] mt-2 flex flex-col gap-4 justify-around items-start'>
                        {OTPStatus === null ? (
                            <>
                                <div className="flex flex-col w-full gap-2">
                                    <div className="relative w-full">
                                        <select title='df' name="countries" id="countries"
                                            onChange={(e) => {
                                                setCountryCode(e.target.value)
                                            }} onBlur={formik.handleBlur}
                                            className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full ${CountryCode != '' && CountryCode != '44' ? (' border border-red-500 text-red-500') : ''} ${formik.values.CountryCode === 'null' || CountryError === true ? (' border border-red-500') : ''} text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} >
                                            <option value="">Select country</option>
                                            <option value="44">+44 United kingdom</option>
                                            <option value="+249">+249 Sudan</option>
                                            {Countries.map((Country: any) => (
                                                <option key={Country.phone_code} value={Country.phone_code.replace('+', '')}>{Country.phone_code} {Country.country_name}</option>
                                            ))}
                                        </select>

                                        <label htmlFor="floating_filled" className={`absolute ${CountryCode != '' && CountryCode != '44' ? 'text-red-500' : ''} text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                            Select country
                                        </label>
                                    </div>
                                    {formik.touched.CountryCode && formik.errors.CountryCode && (
                                        <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.CountryCode}</span>
                                    )}
                                    {formik.values.CountryCode === 'null' ? <span className='mb-2 -mt-2 text-sm text-red-500'>Country is required</span> : ''}
                                    {CountryCode != '' && CountryCode != '44' ? (
                                        <p id="floating_helper_text" className="mt-px mb-2 text-sm text-red-500 dark:text-gray-400">
                                            This service from country is currently unavailable.
                                        </p>

                                    ) : ''}
                                    {CountryError === true ? (
                                        // phone is required
                                        <span className='mt-1 mb-2 text-sm text-red-500'>{formik.errors.CountryCode}</span>
                                    ) : ''}

                                </div>
                                <div className="flex flex-col w-full gap-2">
                                    <div className="relative">
                                        <input type="number" readOnly id="phone" title='phone' name="phone"
                                            value={UserData.phone}
                                            className={`block rounded-lg px-2.5 pb-2.5  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                        <label htmlFor="phone" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                            Mobile number
                                        </label>
                                    </div>

                                </div>
                            </>
                        ) : OTPStatus === 'CodeSent' ? (
                            <div className="flex flex-col w-full gap-2">
                                <div className="relative">
                                    <input type="number" id="OTP" title='OTP' name="OTP"
                                        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.OTP}
                                        className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.OTP && formik.errors.OTP ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer`} placeholder=" " />
                                    <label htmlFor="OTP" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                        OTP Code
                                    </label>
                                </div>
                                {formik.touched.OTP && formik.errors.OTP && (
                                    <span className='mb-2 -mt-2 text-sm text-red-500'>{formik.errors.OTP}</span>
                                )}
                                {OTPError === true ? (
                                    <span className='mt-1 mb-2 text-sm text-red-500'>{formik.errors.OTP}</span>
                                ) : ''}
                            </div>
                        ) : OTPStatus === 'CodeVerified' ? (
                            <div className="flex flex-col items-center justify-center w-full">
                                <div className="w-[200px] h-[200px] flex justify-center items-center ">
                                    <i className="fa-duotone fa-circle-check text-[5.5rem] text-[#0bb60b]"></i>
                                </div>
                                <div className="text-lg w-full text-center font-[500] mb-1 ">Your Phone has been verified</div>
                            </div>
                        ) : ''}

                        <div className="flex items-center w-full my-2">
                            {OTPStatus === null ? (
                                <button onClick={() => {
                                    if (CountryCode != '') {
                                        setLoading(true);
                                        SendCode()
                                    }
                                    if (formik.values.CountryCode === '') {
                                        formik.errors.CountryCode = 'Country is required';
                                        setCountryError(true)
                                    }
                                }} type="button" title="Signin" disabled={loading} className={`bg-primary text-white font-[500] text-lg ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? 'bg-gray-200' : ''} ${loading === true ? '' : 'shadow-md shadow-primary/40'}  rounded-xl px-3 py-2 w-full`}>
                                    {loading === true ? '' : 'Next'}
                                    {loading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                                </button>
                            ) : OTPStatus === 'CodeSent' ? (
                                <button onClick={() => {
                                    if (formik.values.OTP === '') {
                                        formik.errors.OTP = 'OTP is required';
                                        setOTPError(true)
                                    } else {
                                        setOTPLoading(true);
                                        CheckOTP()
                                    }
                                }} type="button" title="Signin" disabled={OTPLoading} className={`bg-primary text-white font-[500] text-lg ${OTPLoading === true ? 'bg-gray-200' : ''} ${OTPLoading === true ? 'bg-gray-200' : ''} ${OTPLoading === true ? '' : 'shadow-md shadow-primary/40'}  rounded-xl px-3 py-2 w-full`}>
                                    {OTPLoading === true ? '' : 'Verify number'}
                                    {OTPLoading === true ? (<i className="fa-solid fa-spinner-third animate-spin"></i>) : ''}
                                </button>
                            ) : ''}
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Phone