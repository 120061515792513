import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Loading from '../../Components/Loading';

const Receivers = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [FilterMenu, setFilterMenu] = useState(false)
    const [loading, setLoading] = useState(true)
    const [Error, setError] = useState(false)

    const [ReceiversList, setReceiversList] = useState<any>([])

    useEffect(() => {
        setLoading(true)
        let Response: any = [];
        global.setTimeout(() => {
            axios.post(`${APIURL}/api/website/beneficiaries/get`, {
                'AppKey': APIKEY,
                'token': localStorage.getItem('Token'),
            }).then(async (DataResponse) => {
                Response = DataResponse.data.response;
                await setReceiversList(DataResponse.data.response.data);
                setLoading(false)
            }).finally(async () => {
                setLoading(false)
            })
        }, 1000)
    }, [])

    const ReceiversUI = ReceiversList.map((Receiver: any) => {

        // let TUID = getUid(Receiver.TID);

        return (
            <div key={Receiver.id} className={`bg-white flex flex-col shadow rounded-lg p-4 border-l-4 border-primary`}>
                <div className="flex justify-between items-center">
                    <div className="flex justify-between items-center gap-3">
                        <div className="flex flex-col text-xs justify-end items-start">
                            <div className="text-base">{Receiver.English_name}</div>
                            <div className="text-xs text-gray-500">{Receiver.Arabic_name}</div>
                            <div className="text-xs text-gray-400">{Receiver.Phone}</div>
                        </div>
                    </div>
                    <div className="flex flex-col text-xs justify-end items-end">
                        <div className="text-sm font-[500] text-gray-600">{Receiver.countries.name}</div>
                        <div className="">{Receiver.date_of_registration}</div>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className=""></div>
                    <div className="flex items-center justify-end mt-2 gap-2">
                        <div className={`w-6 h-6 bg-teal-100  rounded-md flex justify-center items-center`}>
                            <i className={`fa-duotone fa-paper-plane text-teal-600 text-sm`}></i>
                        </div>
                        <div className={`w-6 h-6 bg-indigo-100 rounded-md flex justify-center items-center`}>
                            <i className={`fa-duotone fa-eye text-indigo-600 text-sm`}></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    });
    return (
        <div className='flex flex-col gap-0 justify-start bg-white rounded-t-2xl'>
            {loading === true ? (
                <Loading />
            ) : (
                <div className="p-3 flex flex-col gap-4">
                    <div className="flex justify-between items-center my-4">
                        <div className="">
                            <div className={`w-8 h-8 rounded-md flex justify-center items-center`} onClick={() => setFilterMenu(true)}>
                                <i className={`fa-duotone fa-filter text-gray-400 text-lg`}></i>
                            </div>
                        </div>
                        <div className="">
                            <Link to="/create/receiver">
                                <div className={`w-8 h-8 bg-indigo-100 rounded-md flex justify-center items-center`}>
                                    <i className={`fa-duotone fa-plus text-blue-600 text-lg`}></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {ReceiversUI}
                </div>
            )}

            {FilterMenu === true ? (
                <div className=" absolute top-0 right-0 bottom-0 left-0 w-full h-full bg-black/30" >
                    <div className=" fixed bottom-0 w-full right-0 left-0 z-[99999999999999999] p-4 bg-white h-[73%] rounded-t-[21px] shadow-md flex flex-col" >
                        <div className="flex justify-between items-center mt-1">
                            <button onClick={() => setFilterMenu(false)} title="close" className={`w-8 h-8 bg-gray-100 rounded-md flex justify-center items-center`}>
                                <i className={`fa-duotone fa-circle-xmark text-gray-600 text-lg`}></i>
                            </button>
                            <button
                                onClick={() => {
                                    setFilterMenu(false)
                                }}
                                className="text-sm text-blue-500">Apply</button>
                        </div>
                        <div className="flex flex-col gap-4 mt-7">
                            <div className="flex flex-col gap-4">
                                <div className="text-lg font-[500]">Sort by</div>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Creation Date</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Beneficiary</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Status</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex flex-col gap-4">
                                <div className="text-lg font-[500]">Filter by</div>
                                <div className="grid grid-cols-1 gap-4">
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Creation Date</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Beneficiary</div>
                                        </label>
                                    </div>
                                    <div className="">
                                        <label className='flex items-center gap-2'>
                                            <input type="radio" className='peer' name="Sort" id="" />
                                            <div className="mb-[2px]">Status</div>
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            ) : ''}
        </div>
    )
}

export default Receivers

function getUid(id: any) {
    if (id > 0 && id <= 9) {
        return 'BSTN00' + id;
    } else if (id > 9 && id <= 99) {
        return 'BSTN0' + id;
    } else if (id > 99) {
        return 'BSTN' + id;
    }
}