import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import SingInImg from '../../assets/images/icons/profile.png';

const Profile = () => {
    const APIKEY = process.env.REACT_APP_API_KEY;
    const APIURL = process.env.REACT_APP_API_URL;
    const [UserData, setUserData] = useState<any>([]);
    const [TrnCount, setTrnCount] = useState<any>();
    const [loading, setLoading] = useState(true)
    const [loadingCount, setLoadingCount] = useState(true)

    const navigate = useNavigate();
    useEffect(() => {
        GetData()
        GetTrnCount()
    }, [])

    const GetTrnCount = async () => {
        setLoadingCount(true)
        let Count = '';
        await axios.post(`${APIURL}/api/website/transactions/get/count`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then(async (response) => {
            // console.log(response);
            Count = response.data.response.data
            setLoadingCount(false)
        }).catch(function (error) {
            console.log(error);
        })
        await setTrnCount(Count)
    }

    const GetData = async () => {
        setLoading(true)
        let Data = '';
        await axios.post(`${APIURL}/api/website/user/data`, {
            'AppKey': APIKEY,
            'token': localStorage.getItem('Token'),
        }).then(async (response) => {
            Data = response.data.response.data
            console.log(Data);
            setLoading(false)
        }).catch(function (error) {
            console.log(error);
        })
        await setUserData(Data)
        // console.log(Data);
    }

    // console.log(UserData?.status);

    return (
        <div className='relative flex flex-col items-center justify-around gap-4 bg-primary'>

            <div className="flex items-center justify-between w-full px-4 py-2">
                <button title='back' onClick={() => navigate(-1)} className="text-white ">
                    <i className="text-lg fa-light fa-chevron-left"></i>
                </button>
                <div className="text-base text-white">Profile</div>
                <Link to='/settings' className="">
                    <i className="text-lg text-white fa-light fa-gear"></i>
                </Link>
            </div>

            <div className="flex items-center justify-start w-full gap-5 px-4">
                <div className="w-[5rem] h-[5rem] mt-4 flex justify-center items-center bg-white rounded-3xl">
                    <i className="text-5xl text-primary fa-solid fa-user"></i>
                </div>

                <div className='flex flex-col items-start justify-center gap-1 rounded-t-2xl'>
                    {loading === false ? (
                        <div className="text-base text-white capitalize">{UserData?.full_name.split(' ').shift()} {UserData?.full_name.split(' ')[1]}</div>
                    ) : (<i className="text-white fa-solid fa-spinner-third animate-spin"></i>)}
                    <div className="text-xs text-gray-300">{localStorage?.getItem('Email')?.substring(0, 6)}@..{localStorage?.getItem('Email')?.substr(-2)}</div>
                </div>

            </div>

            <div className='flex flex-col justify-start w-full h-full gap-3 bg-gray-100 rounded-t-2xl'>
                <div className="flex items-center justify-around w-[91%] mx-4 mt-4 mb-2 gap-5 p-4 bg-white rounded-xl">
                    <div className="flex flex-col items-center justify-center gap-1">
                        <div className="text-sm">Transaction count</div>
                        <div className="text-lg font-[600]">{TrnCount}</div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                        <div className="text-sm">Status</div>
                        {UserData?.status != undefined ? (
                            <div className={`text-xs font-[600] ${GetBgColor(UserData?.status)} capitalize px-3 py-2 rounded-xl`}>{UserData?.status}</div>
                        ) : (<i className="text-primary fa-solid fa-spinner-third animate-spin"></i>)}
                    </div>
                </div>
                <div className="flex items-center justify-between mx-4 mt-0 mb-0" >Profile details</div>
                <div className="flex flex-col gap-0 mx-4 bg-white shadow-sm rounded-xl">

                    <div className="flex items-start justify-start gap-2 p-3 border-b border-b-gray-100">
                        <div className="flex flex-col items-start w-full ml-3">
                            <div className="text-xs text-blue-900 font-[600]">Full name</div>
                            <div className="text-sm text-gray-400">{UserData?.full_name}</div>
                        </div>
                    </div>

                    <div className="flex items-start justify-start gap-2 p-3 border-b border-b-gray-100">
                        <div className="flex flex-col items-start w-full ml-3">
                            <div className="text-xs text-blue-900 font-[600]">Address</div>
                            <div className="text-sm text-gray-400">{UserData?.address}</div>
                        </div>
                    </div>
                    <div className="flex items-start justify-start gap-2 p-3 border-b border-b-gray-100">
                        <div className="flex flex-col items-start w-full ml-3">
                            <div className="text-xs text-blue-900 font-[600]">Mobile</div>
                            <div className="text-sm text-gray-400">{UserData?.phone}</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Profile


function GetBgColor(status: any) {
    switch (status) {
        case 'pending':
            return 'bg-yellow-100';
        case 'active':
            return 'bg-green-100';
        case "draft":
            return 'bg-red-100';
        case "suspended":
            return 'bg-red-100';
        case "uploaded":
            return 'bg-yellow-100';
        default:
            return 'bg-yellow-100';
            break;
    }
}
