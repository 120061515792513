import React, { useEffect, useState } from 'react'
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import axios from 'axios';
// import Loading from '../../../Components/Loading';

const Home = () => {
	const navigate = useNavigate();
	const APIKEY = process.env.REACT_APP_API_KEY;
	const APIURL = process.env.REACT_APP_API_URL;
	const [TodayLoading, setTodayLoading] = useState(false)
	const [ReceiversLoading, setReceiversLoading] = useState(false)
	const [TodayTransactions, setTodayTransactions] = useState<any>([])
	const [YesterdayTransactions, setYesterdayTransactions] = useState<any>([])
	const [ReceiversList, setReceiversList] = useState<any>([])
	let date = new Date().toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })

	useEffect(() => {
		setTodayLoading(true)
		setReceiversLoading(true)
		let Response: any = [];
		global.setTimeout(() => {
			axios.post(`${APIURL}/api/website/beneficiaries/get`, {
				'AppKey': APIKEY,
				'token': localStorage.getItem('Token'),
			}).then(async (DataResponse) => {
				Response = DataResponse.data.response;
				setReceiversList(DataResponse.data.response.data);
				setReceiversLoading(false)
			}).finally(async () => {
				setReceiversLoading(false)
			})
			axios.post(`${APIURL}/api/website/transactions/get/today`, {
				'AppKey': APIKEY,
				'limit': 3,
				'type': 'today',
				'token': localStorage.getItem('Token'),
			}).then((DataResponse) => {
				Response = DataResponse.data.response;
				// console.log(DataResponse.data.response)
				setTodayTransactions(DataResponse.data.response.data);
				setTodayLoading(false)
			}).finally(async () => {
				setTodayLoading(false)
			})

			axios.post(`${APIURL}/api/website/transactions/get/today`, {
				'AppKey': APIKEY,
				'limit': 3,
				'type': 'yesterday',
				'token': localStorage.getItem('Token'),
			}).then((DataResponse) => {
				Response = DataResponse.data.response;
				// console.log(DataResponse.data.response)
				setYesterdayTransactions(DataResponse.data.response.data);
				setTodayLoading(false)
			}).finally(async () => {
				setTodayLoading(false)
			})
		}, 100)
	}, [])


	const YesterdayTransactionsUI = YesterdayTransactions?.map((Transaction: any) => {
		let BgColor = GetBgColor(Transaction.status);
		let TextColor = GetTextColor(Transaction.status);
		let Icon = GetIcon(Transaction.status);
		let TUID = getUid(Transaction.TID);
		let TotalToPay = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'GBP',
		}).format(Transaction.Total_to_pay);
		const TRDate = new Intl.DateTimeFormat('en-US', {
			month: 'short',
			day: 'numeric',
		}).format(Date.parse(Transaction.Transaction_date));
		return (
			<div key={Transaction.id} className="flex items-start justify-between gap-2 p-3 border-b border-b-gray-100">
				<div className={`w-10 h-10 ${BgColor}  rounded-xl flex justify-center items-center`}>
					<i className={`fa-thin fa-${Icon} ${TextColor} text-xl`}></i>
				</div>
				<div className="flex flex-col items-start w-[8rem]">
					<div className="text-sm text-blue-900 font-[500]">{Transaction.beneficiaries.English_name.substring(0, 10)}</div>
					<div className="text-xs text-gray-400">{TUID}</div>
				</div>
				<div className="flex flex-col items-end">
					<div className="text-sm text-blue-900 font-[500]">{TotalToPay} {Transaction.Sender_currency}</div>
					<div className="text-xs text-gray-400">{TRDate}</div>
				</div>
			</div>
		)
	});

	const TransactionsUI = TodayTransactions?.map((Transaction: any) => {
		let BgColor = GetBgColor(Transaction.status);
		let TextColor = GetTextColor(Transaction.status);
		let Icon = GetIcon(Transaction.status);
		let TUID = getUid(Transaction.TID);

		let TotalToPay = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'GBP',
		}).format(Transaction.Total_to_pay);
		const floatingPointNumber = 1.567;
		parseFloat(floatingPointNumber.toFixed(2));
		parseFloat(floatingPointNumber.toPrecision(3));
		const TRDate = new Intl.DateTimeFormat('en-US', {
			month: 'short',
			day: 'numeric',
		}).format(Date.parse(Transaction.Transaction_date));
		return (
			<div key={Transaction.id} className="flex items-start justify-between gap-2 p-3 border-b border-b-gray-100">
				<div className={`w-10 h-10 ${BgColor}  rounded-xl flex justify-center items-center`}>
					<i className={`fa-thin fa-${Icon} ${TextColor} text-xl`}></i>
				</div>
				<div className="flex flex-col items-start w-[8rem]">
					<div className="text-sm text-blue-900 font-[500]">{Transaction.beneficiaries.English_name.substring(0, 10)}</div>
					<div className="text-xs text-gray-400">{TUID}</div>
				</div>
				<div className="flex flex-col items-end">
					<div className="text-sm text-blue-900 font-[500]">{TotalToPay} {Transaction.Sender_currency}</div>
					<div className="text-xs text-gray-400">{TRDate}</div>
				</div>
			</div>
		)
	});

	const ReceiversUI = ReceiversList?.map((Receiver: any) => {
		return (
			<SwiperSlide key={Receiver.id}>
				<div className="flex flex-col items-center justify-center gap-3 px-2 py-4 bg-white rounded-lg shadow-sm">
					<div className="flex items-center justify-center bg-gray-100 rounded-md w-14 h-14">
						<i className="text-3xl text-gray-500 fa-duotone fa-user"></i>
					</div>
					<div className="flex flex-col items-center gap-1 ">
						<div className="text-xs text-gray-500 font-[500]">{Receiver.English_name.substring(0, 10)}..</div>
						<div className="mr-1 -mt-1 text-xs text-gray-400">{Receiver.Phone}</div>
					</div>
				</div>
			</SwiperSlide>
		)
	});


	if (!localStorage.getItem('Token')) { //this is how I tried to redirect
		return <Navigate to="/" />
	}

	if (localStorage.getItem('RegistrationStatus') === "not_verified") { //this is how I tried to redirect
		return <Navigate to="/verification/email" />
	} else if (localStorage.getItem('RegistrationStatus') != 'not_verified' && localStorage.getItem('RegistrationStatus') != "completed") {
		return <Navigate to="/sign-up" />
	}

	return (
		<div className='flex flex-col justify-start h-full gap-0 bg-gray-100 rounded-t-2xl'>
			<div className="flex items-center justify-between mx-4 mt-4 mb-0" >
				<div className="text-lg ">
					<Link to="/" className='text-blue-900 font-[600]'>Recent receivers</Link>
				</div>
				<div className="text-sm">
					<Link to="/receivers" className='text-blue-600'>See  all</Link>
				</div>
			</div>
			<div className="p-3 ">
				<Swiper
					spaceBetween={10}
					slidesPerView={3}
				// onSlideChange={() => console.log('slide change')}
				// onSwiper={(swiper) => console.log(swiper)}
				>
					{ReceiversLoading === true ? (
						<div className="flex gap-0 bg-white rounded-xl">
							{[1, 2, 3].map(index => (
								<div key={index} className="w-full max-w-sm p-4 mx-auto border-b rounded-md border-b-gray-100">
									<div className="flex flex-col items-center justify-between gap-1 animate-pulse">
										<div className=" w-[5rem] h-[5rem] bg-gray-100 flex justify-center items-center rounded-md">
										</div>
										<div className="flex flex-col gap-2 items-center w-[80%] mt-2">
											<div className="text-sm bg-blue-900/40 font-[500]  h-2 w-full rounded-full"></div>
											<div className="w-full h-2 text-xs rounded-full bg-gray-400/40"></div>
										</div>

									</div>
								</div>
							))}
						</div>
					) : ReceiversUI}
				</Swiper>
			</div>
			<div className="flex flex-col gap-3 mb-[5rem]">
				<div className="flex items-center justify-between mx-4 mb-0" >
					<div className="text-lg ">
						<Link to="/" className='text-blue-900 font-[600]'>Recent transactions</Link>
					</div>
					<div className="text-sm">
						<Link to="/transactions-history" className='text-blue-600'>See  all</Link>
					</div>
				</div>

				{TodayTransactions.length > 0 ? (
					<div className="flex flex-col gap-2 mx-4 my-2">
						<div className="flex items-center justify-between" >
							<div className="text-base ">
								<div className='text-slate-400 font-[400]'>Today</div>
							</div>
							<div className=""></div>
						</div>
						{TodayLoading === true ? (
							<div className="flex flex-col gap-0 bg-white rounded-xl">
								{[1, 2, 3].map(index => (
									<div key={index} className="w-full max-w-sm p-4 mx-auto border-b rounded-md border-b-gray-100">
										<div className="flex items-center justify-between space-x-4 animate-pulse">
											<div className={`w-10 h-10 bg-blue-200  rounded-xl flex justify-center items-center`}></div>
											<div className="flex flex-col gap-2 items-start w-[5rem]">
												<div className="text-sm bg-blue-900/40 font-[500]  h-2 w-full rounded-full"></div>
												<div className="w-full h-2 text-xs rounded-full bg-gray-400/40"></div>
											</div>
											<div className="flex flex-col gap-2 items-end w-[4rem]">
												<div className="text-sm bg-blue-900/40 font-[500] h-2 w-full rounded-full"></div>
												<div className="w-full h-2 text-xs rounded-full bg-gray-400/40"></div>
											</div>
										</div>
									</div>
								))}
							</div>
						) : (

							<div className="flex flex-col gap-0 bg-white rounded-xl">
								{TransactionsUI}
							</div>
						)}

					</div>
				) : ''}

				{YesterdayTransactions.length > 0 ? (
					<div className="flex flex-col gap-2 mx-4 my-2">
						<div className="flex items-center justify-between" >
							<div className="text-base ">
								<div className='text-slate-400 font-[400]'>Yesterday</div>
							</div>
							<div className=""></div>
						</div>
						<div className="flex flex-col gap-0 bg-white rounded-xl">

							{TodayLoading === true ? (
								<div className="flex flex-col gap-0 bg-white rounded-xl">
									{[1, 2, 3].map(index => (
										<div key={index} className="w-full max-w-sm p-4 mx-auto border-b rounded-md border-b-gray-100">
											<div className="flex items-center justify-between space-x-4 animate-pulse">
												<div className={`w-10 h-10 bg-blue-200  rounded-xl flex justify-center items-center`}></div>
												<div className="flex flex-col gap-2 items-start w-[5rem]">
													<div className="text-sm bg-blue-900/40 font-[500]  h-2 w-full rounded-full"></div>
													<div className="w-full h-2 text-xs rounded-full bg-gray-400/40"></div>
												</div>
												<div className="flex flex-col gap-2 items-end w-[4rem]">
													<div className="text-sm bg-blue-900/40 font-[500] h-2 w-full rounded-full"></div>
													<div className="w-full h-2 text-xs rounded-full bg-gray-400/40"></div>
												</div>
											</div>
										</div>
									))}
								</div>
							) : (

								<div className="flex flex-col gap-0 bg-white rounded-xl">
									{YesterdayTransactionsUI}
								</div>
							)}

						</div>
					</div>
				) : ''}
			</div>

		</div >
	)


}

export default Home


function GetBgColor(status: any) {
	switch (status) {
		case 'ready':
			return 'bg-blue-50';
		case 'paid':
			return 'bg-green-50';
		case "Invalid":
			return 'bg-red-50';
		case "Processing":
			return 'bg-yellow-50';
		default:
			return 'bg-blue-50';
			break;
	}
}

function GetTextColor(status: any) {
	switch (status) {
		case 'ready':
			return 'text-blue-600';
		case 'paid':
			return 'text-green-400';
		case 'Invalid':
			return 'text-red-600';
		case "Processing":
			return 'text-yellow-600';
		default:
			return 'text-blue-600';
			break;
	}
}

function GetBorderColor(status: any) {
	switch (status) {
		case 'ready':
			return 'border-blue-600';
		case 'paid':
			return 'border-green-600';
		case 'Invalid':
			return 'border-red-600';
		case "Processing":
			return 'border-yellow-300';
		default:
			return 'border-blue-600';
			break;
	}
}


function GetIcon(status: any) {
	switch (status) {
		case 'ready':
			return 'circle-check';
		case 'paid':
			return 'circle-check';
		case 'Invalid':
			return 'circle-xmark';
		case "Processing":
			return 'hourglass-clock';
		default:
			return 'circle-check';
			break;
	}
}


function getUid(id: any) {
	if (id > 0 && id <= 9) {
		return 'BSTN00' + id;
	} else if (id > 9 && id <= 99) {
		return 'BSTN0' + id;
	} else if (id > 99) {
		return 'BSTN' + id;
	}
}
