import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import AuthLayout from './layout/AuthLayout';
import MainLayout from './layout/MainLayout';
import Home from './Pages/Home/Home';
import EmailOTP from './Pages/OTPS/EmailOTP/EmailOTP';
import PhoneOTP from './Pages/OTPS/PhoneOTP/PhoneOTP';
import Signin from './Pages/Signin/Signin';
import Signup from './Pages/Signup/Signup';
import CompletePage from './Pages/Signup/CompletePage';
import CreateTransaction from './Pages/Transactions/Create/CreateTransaction';
import SubLayout from './layout/SubLayout';
import History from './Pages/Transactions/History/History';
import Payment from './Pages/Transactions/Payment';
import Receivers from './Pages/Receivers/Receivers';
import Create from './Pages/Receivers/Create/Create';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Email from './Pages/Verification/Email/Email';
import Phone from './Pages/Verification/Phone/Phone';
import SingInImg from './assets/images/icons/New Logo.png';
import CreateV2 from './Pages/Transactions/CreateV2/CreateV2';
import View from './Pages/Transactions/View/View';
import Calculator from './Pages/Calculator/Calculator';
import Profile from './Pages/Profile/Profile';
import Settings from './Pages/Settings/Settings';
import ChangePhone from './Pages/Settings/Verification/Email/ChangePhone';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<AuthLayout />}>
					<Route index element={<Signin />} />
					<Route path="phone" element={<PhoneOTP />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="email-otp" element={<EmailOTP />} />
					<Route path="verification/email" element={<Email />} />
					<Route path="verification/phone" element={<Phone />} />
					<Route path="profile" element={<Profile />} />
					<Route path="settings" element={<Settings />} />
					<Route path="change/phone" element={<ChangePhone />} />

					<Route path="/" element={<SubLayout />}>
						<Route path="calculator" element={<Calculator />} />
						<Route path="send-money" element={<CreateTransaction />} />
						<Route path="send-money-v2" element={<CreateV2 />} />
						<Route path="payment/successful" element={<Payment />} />
						<Route path="receivers" element={<Receivers />} />
						<Route path="create/receiver" element={<Create />} />
						<Route path="transactions-history" element={<History />} />
						<Route path="transactions/:id" element={<View />} />
					</Route>
					<Route path="sign-up" element={<Signup />} />
					<Route path="sign-up/complete" element={<CompletePage />} />
					<Route path="/" element={<MainLayout />}>
						<Route path="home" element={<Home />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	)

}

