import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Countries from '../Components/Countries'
import { useFormik } from 'formik';
const APIKEY = process.env.REACT_APP_API_KEY;
const APIURL = process.env.REACT_APP_API_URL;

const PersonalInfo = ({ formData, setFormData }: any) => {
    const [enabled, setEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [CountriesList, setCountriesList] = useState<any>([])
    const getData = async () => {
        let Countries = '';

        await axios.post(`${APIURL}/api/website/countries`, {
            'AppKey': APIKEY,
        }).then(async (response) => {
            Countries = response.data.response.data;
            setLoading(false)
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
        await setCountriesList(Countries)
    }

    useEffect(() => {
        setLoading(true)
        let Countries = '';
        console.log('Stared')
        getData()
        // global.setTimeout(async () => {
        // }, 2000)
    }, [])

    const validate = (values: any) => {
        let errors = {
            beneficiary: "",
            phone: '',
            EnglishName: "",
            ArabicName: "",
        }

        if (!values.beneficiary) {
            errors.beneficiary = 'Beneficiary is required'
        }

        if (!values.EnglishName) {
            errors.EnglishName = 'English name is required'
        }

        if (!values.phone) {
            errors.phone = 'Phone is required'
        }

        if (!values.ArabicName) {
            errors.ArabicName = 'Arabic name is required'
        }

        return errors
    }
    const formik = useFormik({
        initialValues: {
            beneficiary: '',
            EnglishName: '',
            ArabicName: '',
            phone: '',
        },
        validate,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2))
        },
    })

    return (
        <div>
            <div className='flex flex-col gap-5 justify-around items-start w-full'>
                <div className="text-xl relative left-[-14px] font-[700] mt-2 mb-4 ml-4 ">Account Details</div>

                <div className="w-full ">
                    <div className="flex flex-col gap-2 w-full">
                        <div className="relative">
                            <input type="text" id="EnglishName" title='EnglishName' name="EnglishName"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setFormData({
                                        ...formData,
                                        EnglishName: e.target.value,
                                    });
                                }} onBlur={formik.handleBlur} value={formData.EnglishName}
                                className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.EnglishName && formik.errors.EnglishName != '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="EnglishName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                English name
                            </label>
                        </div>
                        {formik.touched.EnglishName && formik.errors.EnglishName && (
                            <span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.EnglishName}</span>
                        )}
                    </div>
                </div>

                <div className="w-full ">
                    <div className="flex flex-col gap-2 w-full">
                        <div className="relative">
                            <input type="text" id="ArabicName" title='ArabicName' name="ArabicName"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setFormData({
                                        ...formData,
                                        ArabicName: e.target.value,
                                    });
                                }} onBlur={formik.handleBlur} value={formData.ArabicName}
                                className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.ArabicName && formik.errors.ArabicName != '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                            <label htmlFor="ArabicName" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                                Arabic name
                            </label>
                        </div>
                        {formik.touched.ArabicName && formik.errors.ArabicName && (
                            <span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.ArabicName}</span>
                        )}
                    </div>
                </div>


                <div className="flex flex-col gap-2 w-full">
                    <div className="relative">
                        <input type="number" id="phone" title='phone' name="phone"
                            onChange={(e) => {
                                formik.handleChange(e)
                                setFormData({
                                    ...formData,
                                    phone: e.target.value,
                                });
                            }} onBlur={formik.handleBlur} value={formData.phone}
                            className={`block rounded-lg px-2.5 pb-2.5  ${formik.touched.phone && formik.errors.phone != '' ? (' border border-red-500') : ''}  pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-primary peer`} placeholder=" " />
                        <label htmlFor="phone" className={`absolute  text-sm text-gray-400 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-primary peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4`}>
                            Mobile number
                        </label>
                    </div>
                    {formik.touched.phone && formik.errors.phone && (
                        <span className='text-sm text-red-500 -mt-2 mb-2'>{formik.errors.phone}</span>
                    )}
                </div>

                <div className="w-full ">
                    <Countries label='Country' formData={formData} setFormData={setFormData} Data={CountriesList} />
                </div>

            </div>
        </div>
    )
}

export default PersonalInfo